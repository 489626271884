import { Alert, Flex } from "@nef/core";
import React, { useEffect } from "react";
import "utils/index.css";
import { FormFieldLoop } from ".";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";

const RelativeWrapper = styled.div`
  position: relative;
`;

const DisabledOverlay = styled.div`
  ${({ $isDisabled }) => `
      ${
        $isDisabled
          ? `
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: no-drop;
      `
          : `
        "display: none;"}
  `
      }`}
`;
const expandedWidth = "47%";
const LeftFieldsContainer = styled.div`
  ${props => `
    ${
      props.isLeftExpanded
        ? `
            margin-right: 1rem;
            min-width: ${expandedWidth};
            padding-right: 1rem;
            border-right: 2px solid ${getColor("gray", 25)(props)};
            max-width: ${expandedWidth};
            box-sizing: content-box;
          `
        : `
            marginRight: 0;
            width: 100%;
          `
    }
  `}
`;

const RightFieldsContainer = styled.div`
  ${props => `
    ${
      props.isLeftExpanded
        ? `
            min-width: ${expandedWidth};
            max-width: ${expandedWidth};
          `
        : `
            width: 100%;
          `
    }
  `}
`;

const flexStyle = { height: "100%" };

const Form2 = ({
  form,
  isLeftExpanded,
  isDisabled,
  onDisabledClick,
  onInit,
  globalErrors = "",
  leftFieldSet, // [{ key: string, fields: array }, {key: string, fields: array}, ...]
  rightFieldSet, // [{ key: string, fields: array }, {key: string, fields: array}, ...]
}) => {
  const portalRef = true;

  useEffect(() => {
    onInit && onInit();
  }, [onInit]);

  // TODO: height 100% doesn't make any sense here, and yet it works
  // TODO: height on flex is a magic number right now
  return (
    <>
      {(typeof globalErrors === "object" || !!globalErrors?.length) && (
        <Alert color="danger">
          <p>{globalErrors}</p>
        </Alert>
      )}
      <RelativeWrapper>
        <Flex flexDirection={isLeftExpanded ? "row" : "column"} style={flexStyle}>
          <LeftFieldsContainer isLeftExpanded={isLeftExpanded}>
            {leftFieldSet.map((fieldSet, index) => {
              return (
                <FormFieldLoop
                  key={`${fieldSet.key} ${form.key}`}
                  form={form}
                  fields={fieldSet.fields}
                  fieldSetName={fieldSet.key}
                  portalRef={portalRef}
                  showLabel={true}
                  isLast={
                    index === leftFieldSet.length - 1 && (isLeftExpanded || !rightFieldSet.length)
                  }
                />
              );
            })}
          </LeftFieldsContainer>
          <RightFieldsContainer isLeftExpanded={isLeftExpanded}>
            {rightFieldSet.map((fieldSet, index) => (
              <FormFieldLoop
                key={`${fieldSet.key} ${form.key}`}
                form={form}
                fields={fieldSet.fields}
                fieldSetName={fieldSet.key}
                portalRef={portalRef}
                isLast={index === rightFieldSet.length - 1}
              />
            ))}
          </RightFieldsContainer>
        </Flex>
        <DisabledOverlay $isDisabled={isDisabled} onClick={onDisabledClick} />
      </RelativeWrapper>
    </>
  );
};
export default Form2;
