import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormDispatch } from "components/form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StandardHeader, StandardTable, useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { FileTableHeaderMenu, network, useUploadContext } from ".";
import { useViewModelDispatch } from "../context";
import { useUserContext } from "../user";
import "./index.css";
import { useUploadCacheContext, useUploadCacheDispatch } from "./uploadCache";
import { Status } from "../../wksConstants";
import { useDetailsForUpload } from "./useDetailsForUpload";

const FileTable = () => {
  const [uploadData] = useUploadContext();
  const formDispatch = useFormDispatch();
  const standardTableDispatch = useStandardTableDispatch();
  const [user] = useUserContext();
  const viewDispatch = useViewModelDispatch();
  const [uploadCache] = useUploadCacheContext();
  const [{ id, fileName }, setFileDetails] = useState({});
  const uploadCacheDispatch = useUploadCacheDispatch();
  useDetailsForUpload(id, fileName);

  useEffect(() => {
    uploadCacheDispatch({
      type: "SET_GET_FILES",
      payload: true,
    });
  }, [uploadCacheDispatch]);

  useEffect(() => {
    if (uploadCache.status !== Status.NO_STATUS) {
      standardTableDispatch([
        {
          type: "SET_TABLE_DATA_KEEP_SELECTED",
          payload: {
            table: StandardTables.UPLOAD_FILES,
            data: uploadCache.fileData,
            selectByColumn: "id",
          },
        },
        {
          type: "SET_NOT_LOADING",
          payload: { table: StandardTables.UPLOAD_FILES },
        },
        {
          type: "SET_LAST_QUERY_DATE",
          payload: { table: StandardTables.UPLOAD_FILES },
        },
      ]);
    }
  }, [standardTableDispatch, uploadCache.fileData, uploadCache.status]);

  const tableHeader = useCallback(() => <StandardHeader title={"Upload List"} />, []);
  const tableHeaderMenu = <FileTableHeaderMenu />;
  const additionalRowClick = useCallback(
    e => {
      const { id, fileName, countAccepted, countRejected, countFailed, countCsvRows } =
        e.row.original;
      if ((countAccepted + countRejected + countFailed) / countCsvRows === 1) {
        setFileDetails({ id, fileName });
      } else if (id < 0) {
        setFileDetails({ id, fileName });

        const fileIssues = uploadData.fileIssues[id];
        const standardTableDispatches = [
          {
            type: "HANDLE_ROWS_SELECTED",
            payload: {
              table: StandardTables.UPLOAD_FILES,
              rows: { [e.row.id.toString()]: true },
              isSingleSelect: true,
            },
          },
          {
            type: "SET_TABLE_DATA",
            payload: {
              table: StandardTables.UPLOAD_FILE_ISSUES,
              data: fileIssues,
            },
          },
        ];
        standardTableDispatch(standardTableDispatches);
        viewDispatch({
          type: "DISABLE_FORM",
          payload: { form: Forms.UPLOAD_REPAIR },
        });
        formDispatch({
          type: "RESET_FORM",
          payload: { form: Forms.UPLOAD_REPAIR, entitlements: user.entitlements },
        });
      } else {
        NotificationHub.send("primary", `${fileName} is still uploading.`);
      }
    },
    [standardTableDispatch, viewDispatch, formDispatch, user, uploadData.fileIssues]
  );
  return (
    <StandardTable
      header={tableHeader}
      headerMenu={tableHeaderMenu}
      table={StandardTables.UPLOAD_FILES}
      additionalRowClick={additionalRowClick}
      isSingleSelect={true}
      isColumnsVirtualized={false}
      isFilterable={false}
      hideRowCount={false}
      hideSelectedCount={true}
      hideQueryDate={true}
    />
  );
};

export default FileTable;
