import { Tag } from "@nef/core";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { POR, PORModel, PORStatus, PvReject, PvRejectModel } from "components/pvRejects/constant";
import { RejectCol } from "./rejectColumn";
import moment from "moment-timezone";
import {
  LATE_TRADE_MOD_LABEL,
  LATE_TRADE_MOD_VALUE,
  TRF_LABELS,
} from "components/fields/fieldConstants";
import { getSideLabel } from "utils/js.utils";
import { INP_MOD3_LABEL } from "components/standardTable/columnDefinitions";

export const PVR_DATE_FORMAT = "YYYY-MM-DD";
export const PVR_TIME_FORMAT = "HH:mm:ss";
export const PVR_DATE_TIME_FORMAT = `${PVR_DATE_FORMAT} ${PVR_TIME_FORMAT}`;
const JAN_1_1970 = "01/01/1970";
const MICRO_TO_SECONDS = 1000000;

const reportTypeValues = [" "];
const reportTypeLabel = "Report";
const clearTypeValues = [" ", "G"];
const clearTypeLabel = "Clear";
const riskTypeValues = ["Y"];
const riskTypeLabel = "Risk";

const getReportTypeLabel = (row: PvReject) => {
  const reportTypes = [];
  if (reportTypeValues.includes(row[PvRejectModel.TRADE_REPORT_FLAG])) {
    reportTypes.push(reportTypeLabel);
  }
  if (clearTypeValues.includes(row[PvRejectModel.CLEARING_FLAG])) {
    reportTypes.push(clearTypeLabel);
  }
  if (riskTypeValues.includes(row[PvRejectModel.RISK_ELIGIBLE_FLAG])) {
    reportTypes.push(riskTypeLabel);
  }
  return reportTypes.join(", ");
};

export const pvRejectColumns = [
  {
    Header: "ID",
    id: PvRejectModel.ID,
    accessor: PvRejectModel.ID,
  },
  {
    Header: "Reject Reason",
    id: PvRejectModel.REJECT_TEXT,
    accessor: PvRejectModel.REJECT_TEXT,
    Cell: ({ row }: { row: { original: PvReject } }) => {
      return (
        <TooltipTableCell
          id={`error_message_${row.original[PvRejectModel.ID]}`}
          tooltipContent={undefined}
          align={undefined}
        >
          {row.original[PvRejectModel.REJECT_TEXT]}
        </TooltipTableCell>
      );
    },
    width: 200,
  },
  {
    Header: "Entry MPID",
    id: PvRejectModel.ENTRY_MPID,
    accessor: PvRejectModel.ENTRY_MPID,
  },
  {
    Header: "Exec MPID",
    id: PvRejectModel.EXEC_MPID,
    accessor: PvRejectModel.EXEC_MPID,
    width: 90,
  },
  {
    Header: "Contra MPID",
    id: PvRejectModel.CONTRA_MPID,
    accessor: PvRejectModel.CONTRA_MPID,
    width: 110,
  },
  {
    Header: "TRF",
    id: PvRejectModel.TRF,
    accessor: (row: PvReject) => TRF_LABELS[row[PvRejectModel.TRF]],
    width: 85,
  },
  {
    Header: "Symbol",
    id: PvRejectModel.SYMBOL,
    accessor: PvRejectModel.SYMBOL,
    width: 90,
  },
  {
    Header: "Side",
    id: PvRejectModel.SIDE,
    accessor: (row: PvReject) => getSideLabel(row[PvRejectModel.SIDE]),
    width: 100,
  },
  {
    Header: "Quantity",
    id: PvRejectModel.QUANTITY,
    accessor: PvRejectModel.QUANTITY,
    width: 90,
  },
  {
    Header: "Price",
    id: PvRejectModel.PRICE,
    accessor: PvRejectModel.PRICE,
    width: 90,
  },
  {
    Header: INP_MOD3_LABEL,
    id: PvRejectModel.ENTRY_MOD_3,
    accessor: (row: PvReject) =>
      row[PvRejectModel.ENTRY_MOD_3]
        ? LATE_TRADE_MOD_LABEL[row[PvRejectModel.ENTRY_MOD_3]]
        : LATE_TRADE_MOD_LABEL[LATE_TRADE_MOD_VALUE.NONE],
    width: 185,
  },
  {
    Header: "Report Type",
    id: PvRejectModel.TRADE_REPORT_FLAG,
    accessor: (row: PvReject) => getReportTypeLabel(row),
    width: 150,
  },
  {
    Header: "Execution Date",
    id: PvRejectModel.EXECUTION_DATE,
    accessor: (row: PvReject) => {
      const d = new Date(JAN_1_1970);
      d.setDate(d.getDate() + row[PvRejectModel.LAST_UPDATE_DATE]);
      return moment(d).format(PVR_DATE_FORMAT);
    },
    width: 130,
  },
  {
    Header: "Execution Time",
    id: PvRejectModel.EXECUTION_TIME_NS,
    accessor: (row: PvReject) => {
      const d = new Date(JAN_1_1970);
      const hours = row[PvRejectModel.EXECUTION_TIME_NS] / MICRO_TO_SECONDS / 3600;
      const minutes =
        row[PvRejectModel.EXECUTION_TIME_NS] / MICRO_TO_SECONDS / 60 - Math.floor(hours) * 60;
      const seconds =
        row[PvRejectModel.EXECUTION_TIME_NS] / MICRO_TO_SECONDS -
        Math.floor(hours) * 3600 -
        Math.floor(minutes) * 60;
      d.setHours(Math.floor(hours));
      d.setMinutes(Math.floor(minutes));
      d.setSeconds(Math.floor(seconds));
      return moment(d).format(PVR_TIME_FORMAT);
    },
    width: 130,
  },
  {
    Header: "Last Updated",
    id: PvRejectModel.LAST_UPDATE_DATE,
    accessor: (row: PvReject) => {
      const d = new Date(JAN_1_1970);
      d.setDate(d.getDate() + row[PvRejectModel.LAST_UPDATE_DATE]);
      const hours = row[PvRejectModel.LAST_UPDATE_TIME] / MICRO_TO_SECONDS / 3600;
      const minutes =
        row[PvRejectModel.LAST_UPDATE_TIME] / MICRO_TO_SECONDS / 60 - Math.floor(hours) * 60;
      const seconds =
        row[PvRejectModel.LAST_UPDATE_TIME] / MICRO_TO_SECONDS -
        Math.floor(hours) * 3600 -
        Math.floor(minutes) * 60;
      d.setHours(Math.floor(hours));
      d.setMinutes(Math.floor(minutes));
      d.setSeconds(Math.floor(seconds));
      return moment(d).format(PVR_DATE_TIME_FORMAT);
    },
    width: 130,
  },
];

export const pvRequestColumns = [
  {
    Header: "Status",
    id: PORModel.REQUEST_STATUS,
    accessor: PORModel.REQUEST_STATUS,
    width: 115,
    Cell: ({ row }: { row: { original: PvReject } }) => {
      let color = "success";
      if (row.original[PORModel.REQUEST_STATUS] === PORStatus.DENIED) {
        color = "danger";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.PENDING) {
        color = "primary";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.EXPIRED) {
        color = "warning";
      }
      return <Tag color={color}>{row.original[PORModel.REQUEST_STATUS]}</Tag>;
    },
  },
  {
    Header: "Symbol",
    id: PORModel.SYMBOL,
    accessor: PORModel.SYMBOL,
    width: 75,
  },
  { Header: "MPID", id: PORModel.MPID, accessor: PORModel.MPID, width: 75 },
  {
    Header: "Rejects",
    id: PORModel.REJECTS,
    accessor: PORModel.REJECTS,
    Cell: ({ row }: { row: { original: POR } }) => (
      <RejectCol row={row.original} mpid={row.original.mpid} symbol={row.original.symbol} />
    ),
    sortType: (a: { original: POR }, b: { original: POR }) => {
      return (a.original[PORModel.REJECTS] || 0) - (b.original[PORModel.REJECTS] || 0);
    },
    width: 75,
  },
  {
    Header: "Requestor",
    id: PORModel.REQUESTOR_EMAIL,
    accessor: PORModel.REQUESTOR_EMAIL,
    width: 150,
  },
  { Header: "Request ID", id: PORModel.ID, accessor: PORModel.ID, width: 80 },
  {
    Header: "Last Update",
    id: PORModel.LAST_UDPATE,
    accessor: (row: POR) => {
      if (row[PORModel.LAST_UDPATE]) {
        return moment(row[PORModel.LAST_UDPATE]).format(PVR_DATE_TIME_FORMAT);
      }
      return "N/A";
    },
    width: 160,
  },
];
