import Keycloak from "keycloak-js";

let keycloak = null;
export const getKeycloak = () => {
  return keycloak;
};

export const initializeKeycloak = () => {
  keycloak = new Keycloak({
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_URL_KEYCLOAK,
    clientId: process.env.REACT_APP_KEYCLOAK_RESOURCE,
    redirectUri: "*",
  });
  const maxRetry = 5;
  const updateToken = currAttempt => {
    keycloak.updateToken(intervalSeconds + 60).then(
      () => {
        /* token was updated or didn't need to be updated - don't need to do anything else here */
        console.info(`Update token [${new Date()}]`);
      },
      () => {
        if (currAttempt < maxRetry) {
          console.warn(`Error updating token on attempt ${currAttempt}, retrying...`);
          console.warn(`An error retrieving keycloak token happened [${new Date()}]`);
          updateToken(currAttempt + 1);
        } else {
          console.error("Error updating token, logging out...");
          keycloak.logout();
        }
      }
    );
  };

  const intervalSeconds = 45;
  let intervalId = setInterval(() => {
    updateToken(0);
  }, intervalSeconds * 1000);

  keycloak.onAuthError = () => {
    console.error(`Auth error [${new Date()}]`);
  };

  keycloak.onTokenExpired = () => {
    console.error(`Token expired [${new Date()}]`);
  };

  keycloak.onAuthRefreshSuccess = () => {
    console.info(`Refresh token success [${new Date()}]`);
  };

  keycloak.onAuthRefreshError = () => {
    console.error(`Refresh token error [${new Date()}]`);
  };

  window.addEventListener("focus", () => {
    clearInterval(intervalId);
    updateToken(0);
    intervalId = setInterval(() => {
      updateToken(0);
    }, intervalSeconds * 1000);
  });
};
