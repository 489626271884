import { getSideLabel, addPrefixToField } from "utils/js.utils";
import {
  PTRA_FIELD_PREFIX,
  PTRA_CONFIGURATION_ENTITY,
  PTRA_AGU_ENTITY,
} from "../settings/ptra/fields";
import { KeycloakRoles } from "../user/keycloakRoles";
import {
  EqrcFields,
  EQRC_SHARES_LOCATED_OPTIONS,
  EQRC_SHARES_LOCATED_VALUES,
} from "components/settings/eqrc/constants";
import { EQRC_FIELD_PREFIX } from "../settings/eqrc/constants";
import { USER_PREFEREENCE_NAME } from "components/user/initialDataModelTS";
import { MPIDFormOptionMap } from "../user/constants";
import {
  RASH_CAPACITY_OPTIONS,
  RASH_EXCHANGE_OPTIONS,
  RASH_FIELD_PREFIX,
  RASH_ORDER,
  RASH_PEG_TYPE_OPTIONS,
  RASH_PEG_TYPE_VALUE,
  RASH_SIDE_OPTIONS,
} from "components/orderEntry/constants";
import {
  All_History_Alert_Types,
  EqrcServerActionToRule,
  Limited_History_Alert_Types,
} from "components/settings/eqrc/types.consts";
import { enumKeys } from "components/eqrc/utils";
import { TR_SHORT_SALE_INDICATOR, TR_SIDE } from "./constants";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user/initialDataModel";
import { PV_REJECT_TOP } from "components/pvRejects/client/constant";
import { DATE_FILTER, PV_REJECT_FIELD_PREFIX } from "components/pvRejects/constant";
import { Port_Actions, toTitleCase } from "./fieldConstantTypes";
import { ReportingParty } from "./constants";
import { Form, SelectOption } from "types";
import { PTR_AUDIT_ACTION } from "components/settings/constant";
import {
  BANNER_FIELD_PREFIX,
  BANNER_FORM,
  BANNER_THEME,
} from "components/settings/banner/constant";

export enum FORM_KEY {
  TR_REPORT = "TR_REPORT",
  TR_SCAN = "TR_SCAN",
  TR_REJECTS = "TR_REJECTS",
  TR_MODIFY = "TR_MODIFY",
  TR_REPAIR = "TR_REPAIR",
  TR_COPY = "TR_COPY",
  TR_MATCH = "TR_MATCH",
  UPLOAD_REPAIR = "UPLOAD_REPAIR",
  RIGHT = "RIGHT",
  RIGHT_LIMIT = "RIGHT_LIMIT",
  QUERY = "QUERY",
  ST_MODIFY = "ST_MODIFY",
  ST_REPAIR = "ST_REPAIR",
  ST_MIDDLE = "ST_MIDDLE",
  ST_COPY = "ST_COPY",
  ST_MATCH = "ST_MATCH",
  CV_TOP = "CV_TOP",
  PTR_INTRADAY_LIMITS = "PTR_INTRADAY_LIMITS",
  PTR_AUDIT_TRAIL = "PTR_AUDIT_TRAIL",
  PTR_EXPORT_LIMITS = "PTR_EXPORT_LIMITS",
  PTR_INTRADAY_AND_NEXTDAY = "PTR_INTRADAY_AND_NEXTDAY",
  SETTINGS_RSP = "SETTINGS_RSP",
  SETTINGS_TR = "SETTINGS_TR",
  SETTINGS_STATS = "SETTINGS_STATS",
  SETTINGS_LIMO = "SETTINGS_LIMO",
  SETTINGS_USER_PREFERENCE = "SETTINGS_USER_PREFERENCE",
  SETTINGS_PTR_LIMIT_EXPORT = "SETTINGS_PTR_LIMIT_EXPORT",
  PTRA_CONFIG = "PTRA_CONFIG",
  PTRA_EDIT = "PTRA_EDIT",
  PTRA_AGU_CONFIG = "PTRA_AGU_CONFIG",
  PTRA_AGU_EDIT = "PTRA_AGU_EDIT",
  PTRA_EMAIL_MANAGER = "PTRA_EMAIL_MANAGER",
  ST_ACCEPT = "ST_ACCEPT",
  RD_CLEARING = "RD_CLEARING",
  RD_AGU = "RD_AGU",
  RD_CUSIP = "RD_CUSIP",
  EQRC_FAT_FINGER = "EQRC_FAT_FINGER",
  EQRC_RESTRICTED_STOCK_LIST = "EQRC_RESTRICTED_STOCK_LIST",
  EQRC_GROSS_EXPOSURE = "EQRC_GROSS_EXPOSURE",
  EQRC_MARKET_IMPACT_CHECK = "EQRC_MARKET_IMPACT_CHECK",
  EQRC_ORDER_TYPE = "EQRC_ORDER_TYPE",
  EQRC_ADV_CHECK = "EQRC_ADV_CHECK",
  EQRC_ORDER_RATE = "EQRC_ORDER_RATE",
  EQRC_SHORT_SALE = "EQRC_SHORT_SALE",
  EQRC_ALERT_CONFIG = "EQRC_ALERT_CONFIG",
  EQRC_NEW_ALERT = "EQRC_NEW_ALERT",
  EQRC_EDIT_ALERT = "EQRC_EDIT_ALERT",
  EQRC_EDIT_STATUS_MENU = "EQRC_EDIT_STATUS_MENU",
  EQRC_EDIT_FAT_FINGER = "EQRC_EDIT_FAT_FINGER",
  EQRC_EDIT_RESTRICTED_STOCK = "EQRC_EDIT_RESTRICTED_STOCK",
  EQRC_EDIT_GROSS_EXPOSURE = "EQRC_EDIT_GROSS_EXPOSURE",
  EQRC_EDIT_MARKET_IMPACT = "EQRC_EDIT_MARKET_IMPACT",
  EQRC_EDIT_ORDER_TYPE = "EQRC_EDIT_ORDER_TYPE",
  EQRC_EDIT_AVG_DAILY_VOLUME = "EQRC_EDIT_AVG_DAILY_VOLUME",
  EQRC_EDIT_ORDER_RATE = "EQRC_EDIT_ORDER_RATE",
  EQRC_EDIT_SHORT_SALE = "EQRC_EDIT_SHORT_SALE",
  EQRC_EDIT_ALERT_CONFIG = "EQRC_EDIT_ALERT_CONFIG",
  EQRC_TOP = "EQRC_TOP",
  EQRC_AUDIT_MENU = "EQRC_AUDIT_MENU",
  EQRC_ACTIONS = "EQRC_ACTIONS",
  EQRC_HISTORY_MENU = "EQRC_HISTORY_MENU",
  EQRC_EDIT_MAX_NOTIONAL_ORDER = "EQRC_EDIT_MAX_NOTIONAL_ORDER",
  EQRC_EDIT_MAX_SHARES_PER_ORDER = "EQRC_EDIT_MAX_SHARES_PER_ORDER",
  EQRC_EDIT_SHARES_LOCATED_CHECK = "EQRC_EDIT_SHARES_LOCATED_CHECK",
  EQRC_MAX_NOTIONAL_ORDER = "EQRC_MAX_NOTIONAL_ORDER",
  EQRC_MAX_SHARES_PER_ORDER = "EQRC_MAX_SHARES_PER_ORDER",
  EQRC_SHARES_LOCATED_CHECK = "EQRC_SHARES_LOCATED_CHECK",
  EQRC_ACTIVE_OR_CONFIGURED_TABLE = "EQRC_ACTIVE_OR_CONFIGURED_TABLE",
  EQRC_SHARES_LOCATED_BROKER_LIST = "EQRC_SHARES_LOCATED_BROKER_LIST",
  ORDER_ENTRY = "ORDER_ENTRY",
  ORDER_CANCEL = "ORDER_CANCEL",
  PV_MONITOR_MEMBER_TOP = "PV_MONITOR_MEMBER_TOP",
  PVR_CONFIG = "PVR_CONFIG",
  PVR_EDIT = "PVR_EDIT",
  PVR_EMAIL_MANAGER = "PVR_EMAIL_MANAGER",
  PVR_RESUBMIT = "PVR_RESUBMIT",
  SETTINGS_BANNER = "SETTINGS_BANNER",
}

export const Forms: { [key in FORM_KEY]: Form } = Object.freeze({
  [FORM_KEY.TR_REPORT]: { key: "TRReport", label: "Report", id: FORM_KEY.TR_REPORT },
  [FORM_KEY.TR_SCAN]: { key: "TRScan", label: "Scan", id: FORM_KEY.TR_SCAN },
  [FORM_KEY.TR_REJECTS]: { key: "TRRejects", label: "Rejects", id: FORM_KEY.TR_REJECTS },
  [FORM_KEY.TR_MODIFY]: { key: "TRModify", label: "Modify", id: FORM_KEY.TR_MODIFY },
  [FORM_KEY.TR_REPAIR]: { key: "TRRepair", label: "Repair", id: FORM_KEY.TR_REPAIR },
  [FORM_KEY.TR_COPY]: { key: "TRCopy", label: "Copy", id: FORM_KEY.TR_COPY },
  [FORM_KEY.TR_MATCH]: { key: "TRMatch", label: "Match", id: FORM_KEY.TR_MATCH },
  [FORM_KEY.UPLOAD_REPAIR]: { key: "UploadRepair", label: "Repair", id: FORM_KEY.UPLOAD_REPAIR },
  [FORM_KEY.RIGHT]: { key: "Right", label: "Stats", id: FORM_KEY.RIGHT },
  [FORM_KEY.RIGHT_LIMIT]: { key: "RightLimit", label: "StatsLimit", id: FORM_KEY.RIGHT_LIMIT },
  [FORM_KEY.QUERY]: { key: "Query", label: "Query", id: FORM_KEY.QUERY },
  [FORM_KEY.ST_MODIFY]: { key: "StatModify", label: "Modify", id: FORM_KEY.ST_MODIFY },
  [FORM_KEY.ST_REPAIR]: { key: "StatRepair", label: "Repair", id: FORM_KEY.ST_REPAIR },
  [FORM_KEY.ST_MIDDLE]: { key: "StatMiddle", label: "Middle", id: FORM_KEY.ST_MIDDLE },
  [FORM_KEY.ST_COPY]: { key: "StatCopy", label: "Copy", id: FORM_KEY.ST_COPY },
  [FORM_KEY.ST_MATCH]: { key: "StatMatch", label: "Match", id: FORM_KEY.ST_MATCH },
  [FORM_KEY.CV_TOP]: { key: "LimitMonitorTop", label: "Top", id: FORM_KEY.CV_TOP },
  [FORM_KEY.PTR_INTRADAY_LIMITS]: {
    key: "PostTradeRiskIntradayLimits",
    label: "Intraday Limits",
    id: FORM_KEY.PTR_INTRADAY_LIMITS,
  },
  [FORM_KEY.PTR_AUDIT_TRAIL]: {
    key: "PostTradeRiskAuditTrail",
    label: "PTR Audit Trail",
    id: FORM_KEY.PTR_AUDIT_TRAIL,
  },
  [FORM_KEY.PTR_EXPORT_LIMITS]: {
    key: "PostTradeRiskExportLimits",
    label: "Export Limits",
    id: FORM_KEY.PTR_EXPORT_LIMITS,
  },
  [FORM_KEY.PTR_INTRADAY_AND_NEXTDAY]: {
    key: "PostTradeRiskIntradayAndNextDay",
    label: "Intraday and Nextday",
    id: FORM_KEY.PTR_INTRADAY_AND_NEXTDAY,
  },
  [FORM_KEY.SETTINGS_RSP]: {
    key: "SettingsRightSidePanel",
    label: "Right Side Panel Settings",
    id: FORM_KEY.SETTINGS_RSP,
  },
  [FORM_KEY.SETTINGS_TR]: {
    key: "SettingsTradeReporting",
    label: "Trade Reporting Settings",
    id: FORM_KEY.SETTINGS_TR,
  },
  [FORM_KEY.SETTINGS_STATS]: {
    key: "SettingsStats",
    label: "Stats Settings",
    id: FORM_KEY.SETTINGS_STATS,
  },
  [FORM_KEY.SETTINGS_LIMO]: {
    key: "SettingsLimitMonitor",
    label: "Limit Monitor Settings",
    id: FORM_KEY.SETTINGS_LIMO,
  },
  [FORM_KEY.SETTINGS_USER_PREFERENCE]: {
    key: "SettingsUserPreference",
    label: "User Preference",
    id: FORM_KEY.SETTINGS_USER_PREFERENCE,
  },
  [FORM_KEY.SETTINGS_PTR_LIMIT_EXPORT]: {
    key: "SettingsPTRLimitExport",
    label: "PTR Limit Export",
    id: FORM_KEY.SETTINGS_PTR_LIMIT_EXPORT,
  },
  [FORM_KEY.PTRA_CONFIG]: {
    key: "SettingsRiskAlerts",
    label: "Risk Alerts Settings",
    id: FORM_KEY.PTRA_CONFIG,
  },
  [FORM_KEY.PTRA_EDIT]: {
    key: "SettingsRiskAlertsEdit",
    label: "Edit Risk Alerts Settings",
    id: FORM_KEY.PTRA_EDIT,
  },
  [FORM_KEY.PTRA_AGU_CONFIG]: {
    key: "SettingsRiskAlertsAGU",
    label: "Risk Alerts Settings",
    id: FORM_KEY.PTRA_AGU_CONFIG,
  },
  [FORM_KEY.PTRA_AGU_EDIT]: {
    key: "SettingsRiskAlertsEditAGU",
    label: "Edit Risk Alerts Settings",
    id: FORM_KEY.PTRA_AGU_EDIT,
  },
  [FORM_KEY.PTRA_EMAIL_MANAGER]: {
    key: "SettingsRiskAlertsEmail",
    label: "PTRA Email Manager",
    id: FORM_KEY.PTRA_EMAIL_MANAGER,
  },
  [FORM_KEY.PVR_CONFIG]: {
    key: "SettingsPvrAlerts",
    label: "PVR Alerts Settings",
    id: FORM_KEY.PTRA_CONFIG,
  },
  [FORM_KEY.PVR_EDIT]: {
    key: "SettingsPvrAlertsEdit",
    label: "Edit PVR Alerts Settings",
    id: FORM_KEY.PTRA_EDIT,
  },
  [FORM_KEY.PVR_EMAIL_MANAGER]: {
    key: "SettingsPvrAlertsEmail",
    label: "PVR Email Manager",
    id: FORM_KEY.PVR_EMAIL_MANAGER,
  },
  [FORM_KEY.PVR_RESUBMIT]: {
    key: "PVRResubmit",
    label: "Price Reject Resubmit",
    id: FORM_KEY.PVR_RESUBMIT,
  },
  [FORM_KEY.ST_ACCEPT]: { key: "STAccept", label: "Accept", id: FORM_KEY.ST_ACCEPT },
  [FORM_KEY.RD_CLEARING]: { key: "RDClearing", label: "Clearing", id: FORM_KEY.RD_CLEARING },
  [FORM_KEY.RD_AGU]: { key: "RDAGU", label: "AGU/QSR", id: FORM_KEY.RD_AGU },
  [FORM_KEY.RD_CUSIP]: { key: "RDCUSIP", label: "Symbol", id: FORM_KEY.RD_CUSIP },
  [FORM_KEY.EQRC_FAT_FINGER]: {
    key: "eqrcFatFinger",
    label: "EQRC Fat Finger",
    id: FORM_KEY.EQRC_FAT_FINGER,
  },
  [FORM_KEY.EQRC_RESTRICTED_STOCK_LIST]: {
    key: "eqrcRestrictedStock",
    label: "EQRC Restricted Stock",
    id: FORM_KEY.EQRC_RESTRICTED_STOCK_LIST,
  },
  [FORM_KEY.EQRC_GROSS_EXPOSURE]: {
    key: "eqrcGrossExposure",
    label: "EQRC Gross Exposure",
    id: FORM_KEY.EQRC_GROSS_EXPOSURE,
  },
  [FORM_KEY.EQRC_MARKET_IMPACT_CHECK]: {
    key: "eqrcMarketImpact",
    label: "EQRC Market Impact",
    id: FORM_KEY.EQRC_MARKET_IMPACT_CHECK,
  },
  [FORM_KEY.EQRC_ORDER_TYPE]: {
    key: "eqrcOrderType",
    label: "EQRC Order Type",
    id: FORM_KEY.EQRC_ORDER_TYPE,
  },
  [FORM_KEY.EQRC_ADV_CHECK]: {
    key: "eqrcAvgDailyVolume",
    label: "EQRC Average Daily Volume",
    id: FORM_KEY.EQRC_ADV_CHECK,
  },
  [FORM_KEY.EQRC_ORDER_RATE]: {
    key: "eqrcOrderRate",
    label: "EQRC Order Rate",
    id: FORM_KEY.EQRC_ORDER_RATE,
  },
  [FORM_KEY.EQRC_SHORT_SALE]: {
    key: "eqrcShortSale",
    label: "EQRC Short Sale",
    id: FORM_KEY.EQRC_SHORT_SALE,
  },
  [FORM_KEY.EQRC_ALERT_CONFIG]: {
    key: "eqrcAlertConfig",
    label: "EQRC Alert Configuration",
    id: FORM_KEY.EQRC_ALERT_CONFIG,
  },
  [FORM_KEY.EQRC_NEW_ALERT]: {
    key: "eqrcNewAlert",
    label: "EQRC New Alert",
    id: FORM_KEY.EQRC_NEW_ALERT,
  },
  [FORM_KEY.EQRC_EDIT_ALERT]: {
    key: "eqrcEditAlert",
    label: "EQRC Edit Alert",
    id: FORM_KEY.EQRC_EDIT_ALERT,
  },
  [FORM_KEY.EQRC_EDIT_STATUS_MENU]: {
    key: "eqrcEditStatusMenu",
    label: "EQRC Edit Status",
    id: FORM_KEY.EQRC_EDIT_STATUS_MENU,
  },
  [FORM_KEY.EQRC_EDIT_FAT_FINGER]: {
    key: "eqrcEditFatFinger",
    label: "EQRC Edit Fat Finger",
    id: FORM_KEY.EQRC_EDIT_FAT_FINGER,
  },
  [FORM_KEY.EQRC_EDIT_RESTRICTED_STOCK]: {
    key: "eqrcEditEditRestrictedStock",
    label: "EQRC Edit Restricted Stock",
    id: FORM_KEY.EQRC_EDIT_RESTRICTED_STOCK,
  },
  [FORM_KEY.EQRC_EDIT_GROSS_EXPOSURE]: {
    key: "eqrcEditGrossExposure",
    label: "EQRC Edit Gross Exposure",
    id: FORM_KEY.EQRC_EDIT_GROSS_EXPOSURE,
  },
  [FORM_KEY.EQRC_EDIT_MARKET_IMPACT]: {
    key: "eqrcEditMarketImpact",
    label: "EQRC Edit Market Impact",
    id: FORM_KEY.EQRC_EDIT_MARKET_IMPACT,
  },
  [FORM_KEY.EQRC_EDIT_ORDER_TYPE]: {
    key: "eqrcEditOrderType",
    label: "EQRC Edit Order Type",
    id: FORM_KEY.EQRC_EDIT_ORDER_TYPE,
  },
  [FORM_KEY.EQRC_EDIT_AVG_DAILY_VOLUME]: {
    key: "eqrcEditAvgDailyVolume",
    label: "EQRC Edit Average Daily Volume",
    id: FORM_KEY.EQRC_EDIT_AVG_DAILY_VOLUME,
  },
  [FORM_KEY.EQRC_EDIT_ORDER_RATE]: {
    key: "eqrcEditOrderRate",
    label: "EQRC Edit Order Rate",
    id: FORM_KEY.EQRC_EDIT_ORDER_RATE,
  },
  [FORM_KEY.EQRC_EDIT_SHORT_SALE]: {
    key: "eqrcEditShortSale",
    label: "EQRC Edit Short Sale",
    id: FORM_KEY.EQRC_EDIT_SHORT_SALE,
  },
  [FORM_KEY.EQRC_EDIT_ALERT_CONFIG]: {
    key: "eqrcEditAlertConfig",
    label: "EQRC Edit Alert Configuration",
    id: FORM_KEY.EQRC_EDIT_ALERT_CONFIG,
  },
  [FORM_KEY.EQRC_TOP]: { key: "eqrctop", label: "EQRC TOP", id: FORM_KEY.EQRC_TOP },
  [FORM_KEY.EQRC_AUDIT_MENU]: {
    key: "eqrcAuditMenu",
    label: "Audit",
    id: FORM_KEY.EQRC_AUDIT_MENU,
  },
  [FORM_KEY.EQRC_ACTIONS]: { key: "eqrcActions", label: "Actions", id: FORM_KEY.EQRC_ACTIONS },
  [FORM_KEY.EQRC_HISTORY_MENU]: {
    key: "eqrcHistoryMenu",
    label: "History",
    id: FORM_KEY.EQRC_HISTORY_MENU,
  },
  [FORM_KEY.EQRC_EDIT_MAX_NOTIONAL_ORDER]: {
    key: "eqrcEditMaxNotionalOrder",
    label: "EQRC EditMax Notional Order",
    id: FORM_KEY.EQRC_EDIT_MAX_NOTIONAL_ORDER,
  },
  [FORM_KEY.EQRC_EDIT_MAX_SHARES_PER_ORDER]: {
    key: "eqrcEditMaxSharesPerOrder",
    label: "EQRC Edit Max Shares Per Order",
    id: FORM_KEY.EQRC_EDIT_MAX_SHARES_PER_ORDER,
  },
  [FORM_KEY.EQRC_EDIT_SHARES_LOCATED_CHECK]: {
    key: "eqrcEditSharesLocatedCheck",
    label: "EQRC Edit Shares Located",
    id: FORM_KEY.EQRC_EDIT_SHARES_LOCATED_CHECK,
  },
  [FORM_KEY.EQRC_MAX_NOTIONAL_ORDER]: {
    key: "eqrcMaxNotionalOrder",
    label: "EQRC Max Notional Order",
    id: FORM_KEY.EQRC_MAX_NOTIONAL_ORDER,
  },
  [FORM_KEY.EQRC_MAX_SHARES_PER_ORDER]: {
    key: "eqrcMaxSharesPerOrder",
    label: "EQRC Max Shares Per Order",
    id: FORM_KEY.EQRC_MAX_SHARES_PER_ORDER,
  },
  [FORM_KEY.EQRC_SHARES_LOCATED_CHECK]: {
    key: "eqrcSharesLocatedCheck",
    label: "Edit Shares Located",
    id: FORM_KEY.EQRC_SHARES_LOCATED_CHECK,
  },
  [FORM_KEY.EQRC_ACTIVE_OR_CONFIGURED_TABLE]: {
    key: "eqrcActiveOrConfiguredTable",
    label: "Active or Configured",
    id: FORM_KEY.EQRC_ACTIVE_OR_CONFIGURED_TABLE,
  },
  [FORM_KEY.EQRC_SHARES_LOCATED_BROKER_LIST]: {
    key: "eqrcSharesLocatedBrokerList",
    label: "Edit Shares Located",
    id: FORM_KEY.EQRC_SHARES_LOCATED_BROKER_LIST,
  },
  [FORM_KEY.ORDER_ENTRY]: { key: "orderEntry", label: "Order Entry", id: FORM_KEY.ORDER_ENTRY },
  [FORM_KEY.ORDER_CANCEL]: { key: "orderCancel", label: "Order Cancel", id: FORM_KEY.ORDER_CANCEL },
  [FORM_KEY.PV_MONITOR_MEMBER_TOP]: {
    key: "PV_MONITOR_MEMBER_TOP",
    label: "PV_MONITOR_MEMBER_TOP",
    id: FORM_KEY.PV_MONITOR_MEMBER_TOP,
  },
  [FORM_KEY.SETTINGS_BANNER]: {
    key: "SETTINGS_BANNER",
    label: "SETTINGS_BANNER",
    id: FORM_KEY.SETTINGS_BANNER,
  },
});

export const MATCH_FIELD_PREFIX = "MATCH_";
export const FieldNames = {
  scanDateType: "scanDateType",
  tradeTimeRange: "tradeTimeRange",
  reportTimeRange: "reportTimeRange",
  agreementQueryType: "agreementQueryType",
  aguParticipant: "aguParticipant",
  qsrParticipant: "qsrParticipant",
  myUserMpids: "myUserMpids", // the list of mpids the server says are attached to this user
  contraShortSaleInd: "contraShortSaleInd",
  mpid: "mpidvals", // aka entryMPID, mpid
  scanGiveupWithMyMpid: "scanGiveupWithMyMpid",
  scanReportedByMyMpid: "scanReportedByMyMpid",
  scanAllPartiesWithMyMPID: "scanAllPartiesWithMyMPID",
  trf: "trfVals", // aka trf
  reportingParty: "reportingParty",
  clearReportRiskVals: "clearReportRiskVals", // aka Clear/Report
  agreement: "agreement", // aka tradeReportFlag
  counterMPID: "counterMPID", // aka contraMPID
  CUSIP: "cusip",
  symbolOrCUSIP: "symbolOrCUSIP",
  correspondentMPID: "correspondentMPID",
  side: "side",
  sideReport: "sideReportOptions",
  symbol: "symbol", // aka mainSymbol
  quantity: "quantity",
  price: "price",
  priceOverride: "priceOverride",
  priceTradeDigit: "priceTradeDigit",
  contract: "contract",
  actDate: "executionDate",
  actTime: "executionTime",
  executionDate: "executionDate",
  executionTime: "executionTime",
  executionTimeNs: "executionTimeNs",
  executingBroker: "executingBroker",
  settlement: "settlement",
  sellerDays: "sellerDays",
  counterCapacity: "counterCapacity", // aka contraCapacity
  counterGiveUpMPID: "counterGiveUpMPID", // aka contraGiveUpMPID
  counterBranchSeqNum: "counterBranchSeqNum", // aka contraBranchSeqNum
  counterClearingNum: "counterClearingNum", // aka contraClearingNum
  capacity: "capacity", // aka executingCapacity
  giveUpMPID: "giveUpMPID", // aka executingGiveUpMPID
  branchSeqNum: "branchSeqNum", // aka executingBranchSeqNum
  clearingNum: "clearingNum", // aka executingClearingNum
  modifier2: "modifier2",
  modifier2Input: "entryModifier2",
  modifier2Time: "modifier2Time",
  modifier2TimeNs: "modifier2TimeNs",
  modifier3: "modifier3",
  modifier3Input: "entryModifier3",
  modifier4: "modifier4",
  modifier4Input: "entryModifier4",
  modifier4Time: "modifier4Time",
  modifier4TimeNs: "modifier4TimeNs",
  MPIDType: "MPIDType",
  tradeThrough: "tradeThrough",
  tradeThroughExempt: "tradeThroughExempt",
  relatedMarketFlag: "relatedMarketFlag",
  intendedMarketFlag: "intendedMarketFlag",
  stepInOut: "stepInOut", // aka Step in/out, StepInOut
  stepInOutVals: "stepInOutVals",
  fee: "fee",
  contractFee: "contractFee",
  clearingPrice: "clearingPrice",
  special: "special", // aka Special
  specialInstructions: "specialInstruction",
  memo: "memo", // aka AdditionalInstructions
  tradeReferenceNum: "tradeReferenceNum", // aka Traderef#
  dateRange: "DateRange",
  lastUpdated: "lastUpdated",
  reportDateRange: "reportDateRange",
  reportDateYear: "reportDateYear",
  waitForTradeUpdate: "waitForTradeUpdate",

  controlNum: "controlNum",
  tradeStatusVals: "tradeStatusVals", // aka tradeStatus
  portUser: "portUser", // aka "Port/User"
  asOf: "asOfVals",

  alleged: "alleged",
  workstationId: "workstationId",
  breakState: "breakState",
  rejectCount: "rejectCount",
  rejectText: "rejectText",
  rejectTexts: "rejectTexts",
  // reportDate: "actDate",
  // tradeReportDate: "actDate",

  originalControlNum: "originalControlNum",
  originalControlDate: "originalControlDate",
  referenceReportingVenue: "referenceReportingVenue",
  timeRange: "timeRange",

  isReversal: "isReversal",
  reversalOriginalControlNum: "reversalOriginalControlNum",
  reversalOriginalControlDate: "reversalOriginalControlDate",
  reversalReferenceReportingVenue: "reversalReferenceReportingVenue",
  reversalFlag: "reversalFlag",

  // new fields for limit monitor
  clearingFirmMPID: "clearingFirmMPID",
  clearingFirmNum: "clearingFirmNum",
  // correspondentMPID: already exists above,
  dataSources: "dataSources",
  isT2EntryAllowed: "isT2EntryAllowed",
  updateTrfLimits: "updateTrfLimits", // what does this come from??
  useNetTrading: "useNetTrading",
  isMaxTradeActive: "maxTradeActive", // what does this come from?? if maxTradeLimit !== null, isActive === true??
  maxTradeLimit: "maxTradeLimit",
  isPerTradeActive: "perTradeActive", // what does this come from??
  perTradeAction: "perTradeAction",
  perTradeBuyLimit: "perTradeBuyLimit",
  perTradeBuyAction: "perTradeBuyAction",
  perTradeSellLimit: "perTradeSellLimit",
  perTradeSellAction: "perTradeSellAction",
  aggregateBuyLimit: "aggregateBuyLimit",
  aggregateSellLimit: "aggregateSellLimit",
  netLimit: "netLimit",
  isAlertActive: "alertActive", // what does this come from??
  buyAlertLimit: "buyAlertLimit",
  sellAlertLimit: "sellAlertLimit",
  netAlertLimit: "netAlertLimit",
  isHoldActive: "holdActive", // what does this come from??
  buyHoldLimit: "buyHoldLimit",
  defaultAction: "defaultAction",
  buyDefaultAction: "buyDefaultAction",
  sellHoldLimit: "sellHoldLimit",
  sellDefaultAction: "sellDefaultAction",
  netHoldLimit: "netHoldLimit",
  netDefaultAction: "netDefaultAction",
  isRejectActive: "rejectActive", // what does this come from??
  buyRejectLimit: "buyRejectLimit",
  sellRejectLimit: "sellRejectLimit",
  netRejectLimit: "netRejectLimit",
  isPostTradeRiskServiceActive: "isPostTradeRiskServiceActive",
  holdDefaultAction: "holdDefaultAction",
  trfLimits: "updateTrfLimits",
  totalBuy: "ba",
  totalSell: "sa",
  totalNet: "na",

  action: "action",

  submissionDate: "submissionDate",
  exportLimitType: "exportLimitType",

  // Ref Data
  clearingScanMPID: "clearingScanMPID",
  issueID: "issueID",
  nasdaqSymbol: "nasdaqSymbol",
  siacSymbol: "siacSymbol",
  tradeEntrySymbol: "tradeEntrySymbol",
  name: "name",
  market: "market",
  description: "description",
  actEligible: "actEligible",
  clearingEligible: "clearingEligible",
  status: "status",
  executingBrokerMPID: "executingBrokerMPID",
  qsrBrokerMPID: "qsrBrokerMPID",
  firstValidDay: "firstValidDay",
  lastValidDay: "lastValidDay",
  firstValidDate: "firstValidDate",

  brokerMPID: "brokerMPID",
  clearingFirmNSCCNumber: "clearingFirmNSCCNumber",
  canEnterAsOfTrades: "canEnterAsOfTrades",
  defaultClearing: "isDefaultClearing",
  clearingRelationship: "clearingRelationship",
  trfeligible: "trfeligible",

  // User Preference (Settings)
  workXTheme: USER_PREFEREENCE_NAME.THEME,
  workXNotificationPlacement: USER_PREFEREENCE_NAME.NOTIFICATION_PLACEMENT,

  ptrNextDayCheckbox: "ptrNextDayCheckbox",
  pvRejectDates: "pvRejectDates",

  stickyFieldsSubmit: "stickyFields_submit",
  stickyFieldsSwitch: "stickyFields_switch",
  confirmTableHotButtons: "confirmTableHotButtons",
  confirmReportQuantity: "confirmReportQuantity",
  confirmReportQuantityNumber: "confirmReportQuantityNumber",

  lateTradeModifier: "lateTradeModifier",
};
export const OptionConstants = {
  [FieldNames.agreement]: {
    qsr: "QSR",
    agu: "AGU",
  },
  [FieldNames.clearReportRiskVals]: {
    risk: "RSK",
    clear: "CLR",
    report: "RPT",
  },
};

export const clearReportRiskOptions = {
  [OptionConstants[FieldNames.clearReportRiskVals].clear as string]: {
    id: OptionConstants[FieldNames.clearReportRiskVals].clear,
    value: OptionConstants[FieldNames.clearReportRiskVals].clear,
    label: "Clear",
    valueLabel: "Clear",
  },
  [OptionConstants[FieldNames.clearReportRiskVals].report as string]: {
    id: OptionConstants[FieldNames.clearReportRiskVals].report,
    value: OptionConstants[FieldNames.clearReportRiskVals].report,
    label: "Report",
  },
  [OptionConstants[FieldNames.clearReportRiskVals].risk as string]: {
    id: OptionConstants[FieldNames.clearReportRiskVals].risk,
    value: OptionConstants[FieldNames.clearReportRiskVals].risk,
    label: "Risk",
  },
};

export const symbolOrCUSIP = {
  SYMBOL: "symbol",
  CUSIP: "cusip",
};

export const MPIDTypes = { CLEARER: "Clearer", BROKER: "Broker" };
export const queryTypes = { AGU: 0, QSR: 1 };
export const queryDirectionTypes = { PARTICIPANT: "R", EXECUTING: "F" };

const defaultActionOptions = [
  { label: "Allow", value: "A" },
  { label: "Inhibit", value: "I" },
];

export const AlertFrequencyValues = {
  REAL_TIME: "rt",
};

export const alertFrequencyOptions = [
  { value: AlertFrequencyValues.REAL_TIME, label: "Real-Time" },
];

export const AlertEditValues = {
  NO: 0,
  YES: 1,
};

export const AlertEditOptions = {
  [AlertEditValues.NO]: { value: AlertEditValues.NO, label: "No" },
  [AlertEditValues.YES]: { value: AlertEditValues.YES, label: "Yes" },
};

export const LIMIT_SIDE_BREACH_VALUES = {
  Both: null,
  Own: "O",
  Counter: "C",
};

export const SettlementValues = {
  Cash: "C",
  NextDay: "N",
  Regular: "@",
  Other: "R",
};

export const SettlementLabels = {
  [SettlementValues.Cash]: `${SettlementValues.Cash} - Cash`,
  [SettlementValues.NextDay]: `${SettlementValues.NextDay} - Next Day`,
  [SettlementValues.Regular]: `${SettlementValues.Regular} - Regular`,
  [SettlementValues.Other]: `${SettlementValues.Other} - Seller's Option`,
};

export const getSettlementLabelFromDTO = (dto: any, includeSellerDays: any) => {
  return `${SettlementLabels[dto[ApiResponseNames.settlement]]}${
    includeSellerDays ? ` / T+${dto[ApiResponseNames.sellerDays] * 1}` : ""
  }`;
};

export const SettlementOptions = {
  [SettlementValues.Cash]: {
    value: SettlementValues.Cash,
    label: SettlementLabels[SettlementValues.Cash],
  },
  [SettlementValues.NextDay]: {
    value: SettlementValues.NextDay,
    label: SettlementLabels[SettlementValues.NextDay],
  },
  [SettlementValues.Regular]: {
    value: SettlementValues.Regular,
    label: SettlementLabels[SettlementValues.Regular],
  },
  [SettlementValues.Other]: {
    value: SettlementValues.Other,
    label: SettlementLabels[SettlementValues.Other],
  },
};

export const WORKX_THEME_VALUE = {
  LIGHT: "light",
  DARK: "dark",
};

const WORKX_THEME_LABEL = {
  LIGHT: "Light Mode",
  DARK: "Dark Mode",
};

export const workXThemeOptions = {
  [WORKX_THEME_VALUE.DARK]: {
    id: WORKX_THEME_VALUE.DARK,
    label: WORKX_THEME_LABEL.DARK,
    value: WORKX_THEME_VALUE.DARK,
  },
  [WORKX_THEME_VALUE.LIGHT]: {
    id: WORKX_THEME_VALUE.LIGHT,
    label: WORKX_THEME_LABEL.LIGHT,
    value: WORKX_THEME_VALUE.LIGHT,
  },
};

export const WORKX_NOTIFICATION_PLACEMENT_VALUES = {
  TOP_RIGHT: "top-right",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
};

const WORKX_NOTIFICATION_PLACEMENT_LABELS = {
  TOP_RIGHT: "Top - Right",
  TOP_LEFT: "Top - Left",
  TOP_CENTER: "Top - Center",
  BOTTOM_RIGHT: "Bottom - Right",
  BOTTOM_LEFT: "Bottom - Left",
  BOTTOM_CENTER: "Bottom - Center",
};

export const workXNotificationPlacementOptions = {
  [WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_CENTER]: {
    id: WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_CENTER,
    label: WORKX_NOTIFICATION_PLACEMENT_LABELS.BOTTOM_CENTER,
    value: WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_CENTER,
  },
  [WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_LEFT]: {
    id: WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_LEFT,
    label: WORKX_NOTIFICATION_PLACEMENT_LABELS.BOTTOM_LEFT,
    value: WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_LEFT,
  },
  [WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_RIGHT]: {
    id: WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_RIGHT,
    label: WORKX_NOTIFICATION_PLACEMENT_LABELS.BOTTOM_RIGHT,
    value: WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_RIGHT,
  },
  [WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_CENTER]: {
    id: WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_CENTER,
    label: WORKX_NOTIFICATION_PLACEMENT_LABELS.TOP_CENTER,
    value: WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_CENTER,
  },
  [WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_LEFT]: {
    id: WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_LEFT,
    label: WORKX_NOTIFICATION_PLACEMENT_LABELS.TOP_LEFT,
    value: WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_LEFT,
  },
  [WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_RIGHT]: {
    id: WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_RIGHT,
    label: WORKX_NOTIFICATION_PLACEMENT_LABELS.TOP_RIGHT,
    value: WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_RIGHT,
  },
};

export const TRADE_SOURCE_VALUES = {
  Carteret: "1",
  Chicago: "2",
  Nasdaq: "Q",
  BX: "B",
  PSX: "P",
  RASH: "R",
};

export const TRADE_SOURCE_LABELS = {
  [TRADE_SOURCE_VALUES.Carteret]: "TRF Carteret",
  [TRADE_SOURCE_VALUES.Chicago]: "TRF Chicago",
  [TRADE_SOURCE_VALUES.Nasdaq]: "Nasdaq",
  [TRADE_SOURCE_VALUES.BX]: "BX",
  [TRADE_SOURCE_VALUES.PSX]: "PSX",
  [TRADE_SOURCE_VALUES.RASH]: "RASH",
};

export const PTR_HOLD_ACTION_VALUES = {
  Allow: "A",
  Inhibit: "I",
};

export const PTR_HOLD_ACTION_OPTIONS = {
  [PTR_HOLD_ACTION_VALUES.Allow]: { label: "Allow", value: PTR_HOLD_ACTION_VALUES.Allow },
  [PTR_HOLD_ACTION_VALUES.Inhibit]: {
    label: "Inhibit",
    value: PTR_HOLD_ACTION_VALUES.Inhibit,
  },
};

export const PTR_PER_TRADE_ACTION_VALUES = {
  Alert: "W",
  Hold: "H",
};

export const PTR_PER_TRADE_ACTION_OPTIONS = {
  [PTR_PER_TRADE_ACTION_VALUES.Alert]: { label: "Alert", value: PTR_PER_TRADE_ACTION_VALUES.Alert },
  [PTR_PER_TRADE_ACTION_VALUES.Hold]: {
    label: "Hold",
    value: PTR_PER_TRADE_ACTION_VALUES.Hold,
  },
};

const TRADE_REJECT_REASONS = [
  "ACT Validation Reject",
  "FUNCTION NOT ALLOWED",
  "ACT ENTRY SUSPENDED",
  "INVALID ACT ENTRY",
  "INVALID BRANCH SEQ #",
  "INVALID B/S",
  "INVALID CLEARANCE ENTRY",
  "INVALID CLEARING NUMBER",
  "INVALID CONTROL NUMBER",
  "INVALID DATE",
  "INVALID ENTRY",
  "INVALID MM GIVE-UP",
  "INVALID MMID",
  "INVALID OE GIVE-UP",
  "INVALID PRICE",
  "INVALID PRICE OVERRIDE",
  "INVALID P/A",
  "INVALID SECURITY ID",
  "INVALID SPECIAL TRADE IND",
  "INVALID STATUS ENTRY",
  "INVALID TIME",
  "INVALID TRADE MODIFIER",
  "INVALID TRADE REPORT OVERRIDE",
  "INVALID VOLUME",
  "MM NOT ACT AUTHORIZED",
  "MMCLR NOT ACT AUTHORIZED",
  "MMGU NOT ACT AUTHORIZED",
  "MMID REQUIRED",
  "NO CLEARING RELATION WITH THIS EXECUTION BROKER",
  "NO CONTROL NUMBER",
  "NO NO/WAS FOR AS-OF TRADES",
  "NOT AUTHORIZED",
  "NOT CROSS TRADE",
  "NOT WITHIN ALLOWABLE TIME",
  "OE NOT ACT AUTHORIZED",
  "OECLR NOT ACT AUTHORIZED",
  "OEGU NOT ACT AUTHORIZED",
  "OEID REQUIRED",
  "ONLY MM MAY CORRECT THIS TRADE",
  "PRICE OUT OF RANGE",
  "PRICE OUT OF OVERRIDE RANGE",
  "INVALID AS-OF",
  "INVALID OEID",
  "NOT AN OPEN TRADE",
  "INVALID TIME COMBINATION",
  "NO ENTRY DATA WAS UPDATED",
  "INVALID SECURITY CLASS",
  "NOT WITHIN CORRECT MARKET",
  "NO SECID CHANGE IN NO/WAS",
  "EXCEEDS MAXIMUM CONTRACT AMOUNT",
  "INVALID QSR ENTRY",
  "INVALID SHORT SALE INDICATOR",
  "EXECUTION TIME GREATER THAN TRADE REPORT TIME",
  "EXECUTION TIME REQUIRED",
  "ISSUE NOT ACT AUTHORIZED",
  "INVALID FUNCTION CODE",
  "NO NO/WAS FOR NON-MEDIA TRADES",
  "REVERSAL NOT ALLOWED",
  "INVALID TRADE REPORT FLAG",
  "INVALID CONTRA P/A",
  "INVALID BLOCKBUSTER ACTION",
  "INVALID ENTRY I1I2 VALUE",
  "INVALID CLEARING PRICE",
  "ISSUE NOT OPEN",
  "INVALID TRADE-THROUGH EXEMPT",
  "INVALID SELLER DAYS",
  "INVALID RELATED MARKET",
  "INVALID ADVERTISEMENT-INST",
  "SECURITY HALTED",
  "NO/WAS NOT SUPPORTED",
  "UNABLE TO VALIDATE PRICE",
  "INVALID VALUE: CLEARING FLAG",
  "INVALID VALUE: CONTRA CAPACITY",
  "INVALID VALUE: EXEC CAPACITY",
  "INVALID VALUE: ORIG CTRL NUMBER",
  "INVALID VALUE: REF REPORTING FACILITY CAPACITY",
  "INVALID VALUE: REVERSAL INDICATOR",
  "INVALID VALUE: TRADE MOD 1",
  "INVALID VALUE: TRADE MOD 2",
  "INVALID VALUE: TRADE MOD 3",
  "INVALID VALUE: TRADE MOD 4",
  "INVALID VALUE: TRADE MODIFIER",
  "INVALID VALUE: SELLER DAYS",
  "INVALID VALUE: SPECIAL TRADE INDICATOR",
  "INVALID VALUE: USER MEMO CTRL NUMBER",
  "TRADE MOD 2 TIME: MUST BE A VALID TIME",
  "TRADE MOD 2 TIME: INTER MKT SWEEP OUT REQUIRED",
  "TRADE MOD 2 TIME: MUST NOT EXCEED TRADE TIME",
  "TRADE MOD 4 TIME: MUST BE A VALID TIME",
  "TRADE MOD 4 TIME: MUST PRECEDE TRADE TIME",
  "TRADE MOD 4 TIME: MUST PRECEDE REPORT TIME",
  "TRADE MOD 4 TIME: MUST BE >10 SEC BEFORE TRADE",
  "TRADE MOD 4 TIME: PRP/STOPPED STOCK REQUIRED",
  "NON-PUB TRADE: TRADE MOD 2 MUST BE BLANK",
  "NON-PUB TRADE: TRADE MOD 3 MUST BE BLANK",
  "NON-PUB TRADE: INVALID TRADE MOD 4",
  "NON-MKT HOURS TRADE: TRADE MOD 2 MUST BE BLANK",
  "REPORTABLE TRADE: INVALID TRADE MOD 4",
  "TRADE MOD 2 MUST BE BLANK FOR NON-TT EXEMPT",
  "SPECIAL TRADE: ONLY TRADE MOD 1 ALLOWED",
  "EXEC CAPACITY REQUIRED",
  "EXEC TRADE: MUST STEPOUT; STEPIN NOT ALLOWED",
  "OMIT CONTRA CAPACITY ON MATCH-ELIG EP TRADE",
  "CONTRA CAPACITY REQUIRED",
  "CONTRA TRADE: MUST STEPIN; STEPOUT NOT ALLOWED",
  "CONTRA TRADE: INVALID CLEARING FLAG",
  "CONTRA TRADE: CLEAR-ONLY NON-REG NOT ALLOWED",
  "OMIT EXEC CAPACITY ON MATCH-ELIG CP TRADE",
  "AGU: EXEC AND CONTRA MPIDS MUST MATCH",
  "AGU: GIVEUP MUST BE POPULATED",
  "AGU/QSR: SIDE MUST NOT BE A CROSS",
  "STEPOUT/IN MUST BE FOR CLEARING",
  "STEPOUT/IN CANNOT BE REVERSED",
  "EP MUST BE SELF-CLEARING OR T+2 ELIGIBLE",
  "CP MUST BE SELF-CLEARING OR T+2 ELIGIBLE",
  "ORIG CTRL DATE MUST BE NUMERIC",
  "ORIG CTRL DATE MUST PRECEDE TODAY",
  "ORIG CTRL DATE MUST PRECEDE TRADE DATE",
  "REVERSAL: ORIG CTRL DATE MISSING",
  "REVERSAL: ORIG CTRL NUMBER MUST NOT BE BLANK",
  "CLEARING COPY MUST HAVE ETMF SYMBOL",
  "SPECIAL TRADE TYPE MUST BE NON-TAPE",
  "TRADE TYPE MUST BE FOR CLEARING",
  "TRADE WITH CLEARING PRICE MUST BE FOR CLEARING",
  "NASDAQ FEE XFER MUST BE NON-TAPE",
  "T+365 AS-OF MUST BE NON-CLEARING",
  "HOLIDAY/WEEKEND TRADE MUST BE NON-CLEARING",
  "RISK MGMT EP MAX TRADE LIMIT EXCEEDED",
  "RISK MGMT CP MAX TRADE LIMIT EXCEEDED",
  "RISK MGMT EP BUY REJECT LIMIT EXCEEDED",
  "RISK MGMT CP BUY REJECT LIMIT EXCEEDED",
  "RISK MGMT EP SELL REJECT LIMIT EXCEEDED",
  "RISK MGMT CP SELL REJECT LIMIT EXCEEDED",
];

export const REJECT_DATE_OPTIONS = {
  [DATE_FILTER.TODAY]: { label: "Today's Rejects", value: DATE_FILTER.TODAY },
  [DATE_FILTER.ALL]: { label: "All Available Rejects", value: DATE_FILTER.ALL },
};

export const REPORTING_PARTY_OPTIONS = {
  [ReportingParty.Exec]: { id: 1, value: ReportingParty.Exec, label: "Executing" },
  [ReportingParty.Contra]: { id: 2, value: ReportingParty.Contra, label: "Contra" },
};

export const SIDE_OPTIONS = {
  [TR_SIDE.BUY]: { id: 2, value: TR_SIDE.BUY, label: getSideLabel(TR_SIDE.BUY) },
  [TR_SIDE.BUY_CUSTOMER_SOLD_SHORT]: {
    id: 9,
    value: TR_SIDE.BUY_CUSTOMER_SOLD_SHORT,
    label: getSideLabel(TR_SIDE.BUY_CUSTOMER_SOLD_SHORT),
  },
  [TR_SIDE.BUY_CUSTOMER_SOLD_SHORT_EXEMPT]: {
    id: 10,
    value: TR_SIDE.BUY_CUSTOMER_SOLD_SHORT_EXEMPT,
    label: getSideLabel(TR_SIDE.BUY_CUSTOMER_SOLD_SHORT_EXEMPT),
  },
  [TR_SIDE.SELL]: { id: 3, value: TR_SIDE.SELL, label: getSideLabel(TR_SIDE.SELL) },
  [TR_SIDE.SELL_SHORT]: {
    id: 4,
    value: TR_SIDE.SELL_SHORT,
    label: getSideLabel(TR_SIDE.SELL_SHORT),
  },
  [TR_SIDE.SHORT_SELL_EXEMPT]: {
    id: 5,
    value: TR_SIDE.SHORT_SELL_EXEMPT,
    label: getSideLabel(TR_SIDE.SHORT_SELL_EXEMPT),
  },
  [TR_SIDE.CROSS]: { id: 6, value: TR_SIDE.CROSS, label: getSideLabel(TR_SIDE.CROSS) },
  [TR_SIDE.CROSS_SELL_SHORT]: {
    id: 7,
    value: TR_SIDE.CROSS_SELL_SHORT,
    label: getSideLabel(TR_SIDE.CROSS_SELL_SHORT),
  },
  [TR_SIDE.CROSS_SELL_SHORT_EXEMPT]: {
    id: 8,
    value: TR_SIDE.CROSS_SELL_SHORT_EXEMPT,
    label: getSideLabel(TR_SIDE.CROSS_SELL_SHORT_EXEMPT),
  },
};

export const PTR_AUDIT_ACTION_OPTIONS = {
  [PTR_AUDIT_ACTION.PTR_INTRADAY_RISK_CONFIG]: {
    id: 1,
    value: PTR_AUDIT_ACTION.PTR_INTRADAY_RISK_CONFIG,
    label: "Intraday",
  },
  [PTR_AUDIT_ACTION.PTR_NEXT_DAY_RISK_CONFIG]: {
    id: 2,
    value: PTR_AUDIT_ACTION.PTR_NEXT_DAY_RISK_CONFIG,
    label: "Next Day",
  },
};

export const PTR_INTRADAY_NEXT_DAY_VALUES = {
  ALL: null,
  INTRADAY: "I",
  NEXT_DAY: "N",
};

export const PTR_INTRADAY_NEXT_DAY_OPTIONS = {
  [PTR_INTRADAY_NEXT_DAY_VALUES.INTRADAY]: {
    value: PTR_INTRADAY_NEXT_DAY_VALUES.INTRADAY,
    label: "Intraday",
  },
  [PTR_INTRADAY_NEXT_DAY_VALUES.NEXT_DAY]: {
    value: PTR_INTRADAY_NEXT_DAY_VALUES.NEXT_DAY,
    label: "Next Day",
  },
};

export enum TRF_VALUES {
  CARTERET = "1",
  CHICAGO = "2",
}

export const TRF_LABELS = {
  [TRF_VALUES.CARTERET]: "Carteret",
  [TRF_VALUES.CHICAGO]: "Chicago",
};

const TRF_OPTIONS = [
  { id: TRF_VALUES.CARTERET, value: TRF_VALUES.CARTERET, label: TRF_LABELS[TRF_VALUES.CARTERET] },
  { id: TRF_VALUES.CHICAGO, value: TRF_VALUES.CHICAGO, label: TRF_LABELS[TRF_VALUES.CHICAGO] },
];

const YES_OR_NO = [
  { id: 1, label: "Yes", value: "true" },
  { id: 2, label: "No", value: "false" },
];

export const LATE_TRADE_MOD_VALUE = {
  NONE: " ",
  NON_MKT: "T",
  NON_MKT_LATE: "U",
  LATE: "Z",
};

export const LATE_TRADE_MOD_LABEL = {
  [LATE_TRADE_MOD_VALUE.NONE]: "None",
  [LATE_TRADE_MOD_VALUE.NON_MKT]: "Pre/Post Trd",
  [LATE_TRADE_MOD_VALUE.NON_MKT_LATE]: "Pre/Post SOOS",
  [LATE_TRADE_MOD_VALUE.LATE]: "Sold",
};

export const LATE_TRADE_MOD_OPTION = {
  [LATE_TRADE_MOD_VALUE.NONE]: {
    id: LATE_TRADE_MOD_VALUE.NONE,
    label: LATE_TRADE_MOD_LABEL[LATE_TRADE_MOD_VALUE.NONE],
    value: LATE_TRADE_MOD_VALUE.NONE,
  },
  [LATE_TRADE_MOD_VALUE.NON_MKT]: {
    id: LATE_TRADE_MOD_VALUE.NON_MKT,
    label: LATE_TRADE_MOD_LABEL[LATE_TRADE_MOD_VALUE.NON_MKT],
    value: LATE_TRADE_MOD_VALUE.NON_MKT,
  },
  [LATE_TRADE_MOD_VALUE.NON_MKT_LATE]: {
    id: LATE_TRADE_MOD_VALUE.NON_MKT_LATE,
    label: LATE_TRADE_MOD_LABEL[LATE_TRADE_MOD_VALUE.NON_MKT_LATE],
    value: LATE_TRADE_MOD_VALUE.NON_MKT_LATE,
  },
  [LATE_TRADE_MOD_VALUE.LATE]: {
    id: LATE_TRADE_MOD_VALUE.LATE,
    label: LATE_TRADE_MOD_LABEL[LATE_TRADE_MOD_VALUE.LATE],
    value: LATE_TRADE_MOD_VALUE.LATE,
  },
};

export const BANNER_THEME_OPTION: { [key in BANNER_THEME]: SelectOption } = {
  [BANNER_THEME.PRIMARY]: {
    id: BANNER_THEME.PRIMARY,
    value: BANNER_THEME.PRIMARY,
    label: BANNER_THEME.PRIMARY,
  },
  [BANNER_THEME.SECONDARY]: {
    id: BANNER_THEME.SECONDARY,
    value: BANNER_THEME.SECONDARY,
    label: BANNER_THEME.SECONDARY,
  },
  [BANNER_THEME.SUCCESS]: {
    id: BANNER_THEME.SUCCESS,
    value: BANNER_THEME.SUCCESS,
    label: BANNER_THEME.SUCCESS,
  },
  [BANNER_THEME.WARNING]: {
    id: BANNER_THEME.WARNING,
    value: BANNER_THEME.WARNING,
    label: BANNER_THEME.WARNING,
  },
  [BANNER_THEME.DANGER]: {
    id: BANNER_THEME.DANGER,
    value: BANNER_THEME.DANGER,
    label: BANNER_THEME.DANGER,
  },
};

export const SelectOptions: {
  [fieldId: string]:
    | SelectOption[]
    | { [key: string]: SelectOption }
    | (() => SelectOption[])
    | ((form: any, formData: any, user: any) => SelectOption[])
    | ((form: any, formData: any, user: any) => Promise<SelectOption[]>)
    | any;
} = {
  [addPrefixToField(BANNER_FIELD_PREFIX, BANNER_FORM.THEME)]: Object.values(BANNER_THEME_OPTION),
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID)]: [],
  [addPrefixToField(PTRA_FIELD_PREFIX, FieldNames.action)]: Object.values(PTR_AUDIT_ACTION_OPTIONS),
  [addPrefixToField(PV_REJECT_FIELD_PREFIX, PV_REJECT_TOP.rejectDate)]:
    Object.values(REJECT_DATE_OPTIONS),
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.exchange)]: Object.values(RASH_EXCHANGE_OPTIONS),
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.pegType)]: Object.values(RASH_PEG_TYPE_OPTIONS),
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.discretionPegType)]: [
    RASH_PEG_TYPE_OPTIONS[RASH_PEG_TYPE_VALUE.MARKET],
    RASH_PEG_TYPE_OPTIONS[RASH_PEG_TYPE_VALUE.MIDPOINT],
    RASH_PEG_TYPE_OPTIONS[RASH_PEG_TYPE_VALUE.NONE],
    RASH_PEG_TYPE_OPTIONS[RASH_PEG_TYPE_VALUE.PRIMARY],
    RASH_PEG_TYPE_OPTIONS[RASH_PEG_TYPE_VALUE.INAV_PEG],
  ],
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.side)]: Object.values(RASH_SIDE_OPTIONS),
  [addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.capacity)]: Object.values(RASH_CAPACITY_OPTIONS),
  [FieldNames.lateTradeModifier]: Object.values(LATE_TRADE_MOD_OPTION),
  [FieldNames.holdDefaultAction]: Object.values(PTR_HOLD_ACTION_VALUES).map(
    value => PTR_HOLD_ACTION_OPTIONS[value]
  ),
  [FieldNames.perTradeBuyAction]: Object.values(PTR_PER_TRADE_ACTION_VALUES).map(
    value => PTR_PER_TRADE_ACTION_OPTIONS[value]
  ),
  [FieldNames.workXTheme]: [
    workXThemeOptions[WORKX_THEME_VALUE.DARK],
    workXThemeOptions[WORKX_THEME_VALUE.LIGHT],
  ],
  [FieldNames.workXNotificationPlacement]: [
    workXNotificationPlacementOptions[WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_CENTER],
    workXNotificationPlacementOptions[WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_LEFT],
    workXNotificationPlacementOptions[WORKX_NOTIFICATION_PLACEMENT_VALUES.BOTTOM_RIGHT],
    workXNotificationPlacementOptions[WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_CENTER],
    workXNotificationPlacementOptions[WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_LEFT],
    workXNotificationPlacementOptions[WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_RIGHT],
  ],
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.intradayNextDay)]: () => [
    { value: null, label: "All" },
    ...Object.values(PTR_INTRADAY_NEXT_DAY_OPTIONS),
  ],
  [addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.emailId)]: () => [],
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedOption)]: [
    EQRC_SHARES_LOCATED_OPTIONS[EQRC_SHARES_LOCATED_VALUES.DISABLED],
    EQRC_SHARES_LOCATED_OPTIONS[EQRC_SHARES_LOCATED_VALUES.ENABLED],
  ],
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertLevel)]: [
    { id: "action", value: "ACTION", label: "Action" },
    { id: "warn", value: "WARN", label: "Warn" },
    { id: "watch", value: "WATCH", label: "Watch" },
  ],
  alertFrequency: alertFrequencyOptions,
  rightViewsContainer: {
    stats: { id: 1, value: "STATS", label: "Real Time Stats" },
    risk: { id: 2, value: "LIMIT", label: "Post - Trade Risk" },
  },
  alertEditOptions: [AlertEditOptions[AlertEditValues.NO], AlertEditOptions[AlertEditValues.YES]],
  rightViews: [], //i am overidden
  [FieldNames.mpid]: (form: any) => {
    let key = form;
    if (typeof form === "object") {
      key = form.key;
    }
    if (MPIDFormOptionMap[key]) {
      return MPIDFormOptionMap[key];
    } else {
      console.warn(`MPID Options not found for ${key}`);
      return [];
    }
  },
  [FieldNames.MPIDType]: [
    { id: "Correspondent", value: MPIDTypes.BROKER, label: "Correspondent" },
    { id: "Clearing", value: MPIDTypes.CLEARER, label: "Clearing" },
  ],
  [FieldNames.agreementQueryType]: [
    { label: "AGU Executing MPID", value: "AGU_MPID" },
    { label: "AGU Participant", value: "AGU_Participant" },
    { label: "QSR Executing MPID", value: "QSR_MPID" },
    { label: "QSR Participant", value: "QSR_Participant" },
  ],
  [FieldNames.trf]: TRF_OPTIONS,
  [FieldNames.reportingParty]: [
    REPORTING_PARTY_OPTIONS[ReportingParty.Exec],
    REPORTING_PARTY_OPTIONS[ReportingParty.Contra],
  ],
  [FieldNames.clearReportRiskVals]: (form: any, formData: any, user: any): SelectOption[] => {
    if (
      [
        Forms.TR_REPORT,
        Forms.TR_REPAIR,
        Forms.TR_MODIFY,
        Forms.TR_COPY,
        Forms.ST_MODIFY,
        Forms.ST_REPAIR,
        Forms.ST_COPY,
        Forms.UPLOAD_REPAIR,
      ].includes(form)
    ) {
      const opts: SelectOption[] = [
        {
          ...(clearReportRiskOptions[
            OptionConstants[FieldNames.clearReportRiskVals].clear as string
          ] as SelectOption),
          label: "Clear and Risk",
        },
        clearReportRiskOptions[
          OptionConstants[FieldNames.clearReportRiskVals].risk as string
        ] as SelectOption,
      ];
      if (!user.entitlements?.[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY]) {
        opts.push(
          clearReportRiskOptions[
            OptionConstants[FieldNames.clearReportRiskVals].report as string
          ] as SelectOption
        );
      }
      return opts;
    } else {
      return [
        clearReportRiskOptions[
          OptionConstants[FieldNames.clearReportRiskVals].report as string
        ] as SelectOption,
        clearReportRiskOptions[
          OptionConstants[FieldNames.clearReportRiskVals].clear as string
        ] as SelectOption,
        clearReportRiskOptions[
          OptionConstants[FieldNames.clearReportRiskVals].risk as string
        ] as SelectOption,
      ];
    }
  },
  [FieldNames.symbolOrCUSIP]: [
    {
      id: "CUSIP",
      value: symbolOrCUSIP.CUSIP,
      label: symbolOrCUSIP.CUSIP,
    },
    {
      id: "Symbol",
      value: symbolOrCUSIP.SYMBOL,
      label: symbolOrCUSIP.SYMBOL,
    },
  ],

  [FieldNames.agreement]: [
    {
      id: OptionConstants[FieldNames.agreement].agu,
      value: OptionConstants[FieldNames.agreement].agu,
      label: "AGU",
    },
    {
      id: OptionConstants[FieldNames.agreement].qsr,
      value: OptionConstants[FieldNames.agreement].qsr,
      label: "QSR",
    },
  ],
  [FieldNames.side]: [
    SIDE_OPTIONS[TR_SIDE.BUY],
    SIDE_OPTIONS[TR_SIDE.BUY_CUSTOMER_SOLD_SHORT],
    SIDE_OPTIONS[TR_SIDE.BUY_CUSTOMER_SOLD_SHORT_EXEMPT],
    SIDE_OPTIONS[TR_SIDE.SELL],
    SIDE_OPTIONS[TR_SIDE.SELL_SHORT],
    SIDE_OPTIONS[TR_SIDE.SHORT_SELL_EXEMPT],
    SIDE_OPTIONS[TR_SIDE.CROSS],
    SIDE_OPTIONS[TR_SIDE.CROSS_SELL_SHORT],
    SIDE_OPTIONS[TR_SIDE.CROSS_SELL_SHORT_EXEMPT],
  ],
  [FieldNames.settlement]: (form: any, data: any, user: any) => {
    const options = [SettlementOptions[SettlementValues.Cash]];
    if (
      form === Forms.TR_SCAN ||
      !user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.t1RegularSettlement]
    ) {
      options.push(SettlementOptions[SettlementValues.NextDay]);
    }
    options.push(SettlementOptions[SettlementValues.Regular]);
    options.push(SettlementOptions[SettlementValues.Other]);
    return options;
  },
  [FieldNames.counterCapacity]: [
    { id: 2, value: "A", label: "Agency" },
    { id: 3, value: "P", label: "Principal" },
    { id: 4, value: "R", label: "Riskless" },
  ],
  [FieldNames.capacity]: [
    { id: 2, value: "A", label: "Agency" },
    { id: 3, value: "P", label: "Principal" },
    { id: 4, value: "R", label: "Riskless" },
  ],
  [FieldNames.modifier2]: [
    { id: 1, value: "F", label: "Intermkt sweep" },
    { id: 2, value: "4", label: "Derivatively Priced" },
    { id: 3, value: "2", label: "Self Help" },
    { id: 4, value: "J", label: "Sub Penny" },
    { id: 5, value: "V", label: "Contingent Trade" },
    { id: 6, value: "3", label: "ISO" },
    { id: 7, value: "7", label: "Error Correct" },
    { id: 8, value: "8", label: "Print Protect" },
  ],
  [FieldNames.modifier2Input]: [
    { id: 1, value: "F", label: "Intermkt sweep" },
    { id: 2, value: "4", label: "Derivatively Priced" },
    { id: 7, value: "7", label: "Qualified Contingent " },
  ],
  [FieldNames.modifier3]: [
    LATE_TRADE_MOD_OPTION[LATE_TRADE_MOD_VALUE.NON_MKT],
    LATE_TRADE_MOD_OPTION[LATE_TRADE_MOD_VALUE.NON_MKT_LATE],
    LATE_TRADE_MOD_OPTION[LATE_TRADE_MOD_VALUE.LATE],
  ],
  [FieldNames.modifier3Input]: [
    { id: 1, value: "T", label: "Pre/Post Trd" },
    { id: 2, value: "U", label: "Pre/Post SOOS" },
    { id: 3, value: "Z", label: "Sold" },
  ],
  [FieldNames.modifier4]: [
    { id: 1, value: "P", label: "Prior Ref Price" },
    { id: 2, value: "W", label: "Avg Price" },
    { id: 3, value: "1", label: "Stop Stock" },
    { id: 4, value: "R", label: "RA px away" },
    { id: 5, value: "X", label: "RX Options Ex" },
  ],
  [FieldNames.modifier4Input]: [
    { id: 1, value: "P", label: "Prior Ref Price" },
    { id: 2, value: "W", label: "Avg Price" },
    { id: 3, value: "I", label: "Odd Lot" },
    { id: 3, value: "V", label: "Contingent" },
    { id: 4, value: "W", label: "SIP Avg Price" },
    { id: 5, value: "B", label: "CTS Avg Price" },
  ],
  [`${FieldNames.modifier4}_scan`]: [
    { id: 1, value: "P", label: "Prior Ref Price" },
    { id: 2, value: "I", label: "Odd Lot" },
    { id: 3, value: "V", label: "Contingent" },
    { id: 4, value: "W", label: "SIP Avg Prc" },
    { id: 5, value: "B", label: "CTS Avg Prc" },
  ],

  [FieldNames.relatedMarketFlag]: [
    { id: 1, value: "A", label: "NYSE American" },
    { id: 2, value: "B", label: "Nasdaq BX" },
    { id: 20, value: "C", label: "NYSE National" },
    { id: 3, value: "E", label: "MEMX" },
    { id: 24, value: "F", label: "Foreign Market" },
    { id: 21, value: "G", label: "BATS Y" },
    { id: 4, value: "H", label: "BATS" },
    { id: 5, value: "I", label: "ISE" },
    { id: 6, value: "J", label: "EDGA" },
    { id: 7, value: "K", label: "EDGX" },
    { id: 8, value: "L", label: "LTSE" },
    { id: 22, value: "M", label: "NYSE Chicago" },
    { id: 9, value: "N", label: "NYSE" },
    { id: 10, value: "O", label: "Unknown" },
    { id: 25, value: "P", label: "ARCA" },
    { id: 11, value: "Q", label: "Nasdaq" },
    { id: 23, value: "U", label: "Unspecified MKTs" },
    { id: 12, value: "V", label: "IEX" },
    { id: 13, value: "W", label: "CBOE" },
    { id: 14, value: "X", label: "Nasdaq PSX" },
    { id: 15, value: "Y", label: "MIAX" },
    { id: 16, value: "0", label: "ADF" },
    { id: 17, value: "1", label: "TRF Carteret" },
    { id: 18, value: "2", label: "TRF Chicago" },
    { id: 19, value: "3", label: "NYSE TRF" },
  ],
  [FieldNames.intendedMarketFlag]: [
    { id: "nasdaq", value: "Q", label: "Nasdaq" },
    { id: "trf", value: "D", label: "TRF" },
  ],
  [FieldNames.stepInOut]: [
    { id: "stepOut", value: "SO", label: "Step Out" },
    { id: "stepOutFee", value: "SOWF", label: "Step Out w/fee" },
    { id: "stepIn", value: "SI", label: "Step In" },
  ],
  [FieldNames.stepInOutVals]: [
    { id: "none", value: " ", label: "<none>" },
    { id: "stepOut", value: "SO", label: "Step Out" },
    { id: "stepOutFee", value: "SOWF", label: "Step Out w/fee" },
    { id: "stepIn", value: "SI", label: "Step In" },
  ],
  [FieldNames.specialInstructions]: [
    { id: "clearingCopy", value: "CC", label: "Clearing Copy" },
    { id: "positionTransfer", value: "PT", label: "Position Transfer" },
    { id: "clearingNonReg", value: "CNR", label: "Clearing Non Reg" },
    { id: "nasdaqSalesFeeTransfer", value: "NSFX", label: "Nasdaq Sales Fee Transfer" },
  ],
  [FieldNames.tradeStatusVals]: [
    { id: 1, value: "A", label: "Accepted" },
    { id: 2, value: "B", label: "Broken" },
    { id: 3, value: "C", label: "Canceled" },
    { id: 4, value: "D", label: "Declined" },
    { id: 5, value: "E", label: "Error" },
    { id: 6, value: "F", label: "Force Matched" },
    { id: 7, value: "G", label: "One Sided Clear" },
    { id: 8, value: "H", label: "Held" },
    { id: 9, value: "I", label: "Inhibited" },
    { id: 10, value: "K", label: "Killed" },
    { id: 11, value: "L", label: "Auto Locked-in" },
    { id: 12, value: "M", label: "Matched" },
    { id: 13, value: "O", label: "Open" },
    { id: 15, value: "R", label: "Locked-in" },
    { id: 16, value: "T", label: "Tape" },
    { id: 17, value: "U", label: "Unmatched" },
    { id: 18, value: "V", label: "Carried Over" },
    { id: 19, value: "X", label: "Purged" },
  ],
  tradeStatusQueryTool: [
    { id: 1, value: "A", label: "Accepted" },
    { id: 2, value: "B", label: "Broken" },
    { id: 3, value: "C", label: "Canceled" },
    { id: 4, value: "D", label: "Declined" },
    { id: 5, value: "E", label: "Error" },
    { id: 6, value: "F", label: "Force Matched" },
    { id: 7, value: "G", label: "One Sided Clear" },
    { id: 8, value: "H", label: "Held" },
    { id: 9, value: "I", label: "Inhibited" },
    { id: 10, value: "K", label: "Killed" },
    { id: 11, value: "L", label: "Auto Locked-in" },
    { id: 12, value: "M", label: "Matched" },
    { id: 13, value: "O", label: "Open" },
    { id: 14, value: "R", label: "Locked-in" },
    { id: 15, value: "T", label: "Tape" },
    { id: 16, value: "U", label: "Unmatched" },
    { id: 17, value: "V", label: "Carried Over" },
    { id: 18, value: "X", label: "Purged" },
  ],
  [FieldNames.portUser]: (): SelectOption[] => [], //i will be replaced dynamically, one the user's username is know

  [FieldNames.asOf]: [
    { id: 1, value: "L", label: "Late" },
    { id: 2, value: "R", label: "Reversal" },
    { id: 3, value: "O", label: "All Other" },
  ],
  // asOfFlag: [
  //   { id: 1, value: " ", label: "T Day" },
  //   { id: 2, value: "Y", label: "As Of" },
  //   { id: 3, value: "O", label: "All Other" },
  // ],
  [FieldNames.contraShortSaleInd]: [
    { id: 1, value: TR_SHORT_SALE_INDICATOR.SOLD_SHORT, label: "Sold Short" },
    { id: 2, value: TR_SHORT_SALE_INDICATOR.SOLD_SHORT_EXEMPT, label: "Sold Short Exempt" },
  ],
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.marketImpact)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedSSE)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.isoOrders)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleOrders)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.nonMarketOrders)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.postMarketTrading)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.premarketTrading)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleExempt)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.buyMarketIPO)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeAuctionOrders)]: YES_OR_NO,
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.includeIsoOrders)]: YES_OR_NO,

  [FieldNames.special]: YES_OR_NO,
  [FieldNames.priceOverride]: YES_OR_NO,
  [FieldNames.tradeThroughExempt]: YES_OR_NO,
  [FieldNames.referenceReportingVenue]: [
    { id: 1, label: "ADF", value: "A" },
    { id: 2, label: "Chicago", value: "B" },
    { id: 3, label: "Carteret", value: "Q" },
    { id: 4, label: "NYSE TRF", value: "N" },
    { id: 5, label: "ORF", value: "O" },
  ],
  [FieldNames.clearingFirmMPID]: (): SelectOption[] => [],
  [FieldNames.correspondentMPID]: (): SelectOption[] => [],
  [FieldNames.dataSources]: [
    {
      label: TRADE_SOURCE_LABELS[TRADE_SOURCE_VALUES.Carteret],
      value: TRADE_SOURCE_VALUES.Carteret,
    },
    { label: TRADE_SOURCE_LABELS[TRADE_SOURCE_VALUES.Chicago], value: TRADE_SOURCE_VALUES.Chicago },
    { label: TRADE_SOURCE_LABELS[TRADE_SOURCE_VALUES.Nasdaq], value: TRADE_SOURCE_VALUES.Nasdaq },
    { label: TRADE_SOURCE_LABELS[TRADE_SOURCE_VALUES.BX], value: TRADE_SOURCE_VALUES.BX },
    { label: TRADE_SOURCE_LABELS[TRADE_SOURCE_VALUES.PSX], value: TRADE_SOURCE_VALUES.PSX },
    { label: TRADE_SOURCE_LABELS[TRADE_SOURCE_VALUES.RASH], value: TRADE_SOURCE_VALUES.RASH },
  ],
  [FieldNames.buyDefaultAction]: defaultActionOptions,
  [FieldNames.sellDefaultAction]: defaultActionOptions,
  [FieldNames.netDefaultAction]: defaultActionOptions,
  [FieldNames.sellDefaultAction]: defaultActionOptions,
  [FieldNames.sellDefaultAction]: defaultActionOptions,
  [FieldNames.sellDefaultAction]: defaultActionOptions,
  [FieldNames.submissionDate]: [
    // use REJECT_DATE_OPTIONS when this support T-2
    { label: "T Date", value: "0" },
    { label: "T-1 Date", value: "1" },
  ],
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.breachAction)]: [
    { label: "REJECT", value: "REJECT" },
    { label: "CANCEL NON CROSS", value: "CANCEL_NON_CROSS" },
    { label: "CANCEL ALL", value: "CANCEL_ALL" },
  ],
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.ruleType)]: [],
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertType)]: (
    form: any,
    data: any,
    user: any
  ) => {
    const options = [];
    let sourceForOptions;
    if (user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcShowSubOrderTypes]) {
      sourceForOptions = All_History_Alert_Types;
    } else {
      sourceForOptions = Limited_History_Alert_Types;
    }
    for (const rule of enumKeys(sourceForOptions)) {
      options.push({
        label: sourceForOptions[rule],
        value: rule,
      });
    }
    return options;
  },
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portAction)]: [
    { label: toTitleCase(Port_Actions.DISABLE), value: Port_Actions.DISABLE },
    { label: toTitleCase(Port_Actions.ENABLE), value: Port_Actions.ENABLE },
    { label: toTitleCase(Port_Actions.UNBREACH), value: Port_Actions.UNBREACH },
  ],
  [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction)]: [
    { label: "Non-Cross Only", value: "CANCEL_NON_CROSS" },
    { label: "All", value: "CANCEL_ALL" },
  ],
  [FieldNames.rejectTexts]: TRADE_REJECT_REASONS.map(reason => ({ label: reason, value: reason })),
};

for (const rule of enumKeys(EqrcServerActionToRule)) {
  SelectOptions[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.ruleType)].push({
    label: EqrcServerActionToRule[rule],
    value: rule,
  });
}

export const eqrcExchangeMpidPortForms = [
  Forms.EQRC_ACTIONS,
  Forms.EQRC_TOP,
  Forms.EQRC_SHORT_SALE,
  Forms.EQRC_ORDER_RATE,
  Forms.EQRC_MARKET_IMPACT_CHECK,
  Forms.EQRC_GROSS_EXPOSURE,
  Forms.EQRC_RESTRICTED_STOCK_LIST,
  Forms.EQRC_ORDER_TYPE,
  Forms.EQRC_FAT_FINGER,
  Forms.EQRC_ADV_CHECK,
  Forms.EQRC_AUDIT_MENU,
  Forms.EQRC_HISTORY_MENU,
  Forms.EQRC_MAX_NOTIONAL_ORDER,
  Forms.EQRC_MAX_SHARES_PER_ORDER,
  Forms.EQRC_SHARES_LOCATED_CHECK,
  Forms.EQRC_ALERT_CONFIG,
];
eqrcExchangeMpidPortForms.forEach(form => {
  SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid)}${form.key}`] = () => [];
  SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port)}${form.key}`] = () => [];
  SelectOptions[`${addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange)}${form.key}`] =
    () => [];
});

export const GrayableFields = [
  FieldNames.special,
  FieldNames.specialInstructions,
  FieldNames.fee,
  FieldNames.stepInOut,
  FieldNames.agreement,
  FieldNames.scanGiveupWithMyMpid,
  FieldNames.giveUpMPID,
  FieldNames.counterGiveUpMPID,
  FieldNames.intendedMarketFlag,

  // limo preferences
  FieldNames.isAlertActive,
  FieldNames.isHoldActive,
  FieldNames.isRejectActive,
  FieldNames.isPerTradeActive,
  FieldNames.isMaxTradeActive,
];

export const ApiResponseNames = {
  userTrade: "userTrade",
  agreementQueryType: "agreementQueryType",
  aguParticipant: "aguParticipant",
  qsrParticipant: "qsrParticipant",
  account: "account",
  actDate: "actDate",
  actDateTime: "actDateTime",
  actReadyFlag: "actReadyFlag",
  actTime: "actTime",
  adjustedSpecialTradeFlag: "adjustedSpecialTradeFlag",
  adjustedStepOut: "adjustedStepOut",
  advertisementInstruction: "advertisementInstruction",
  asOf: "asOfFlag",
  agreement: FieldNames.agreement,
  breakState: "breakState",
  carryOverFlag: "carryOverFlag",
  clearingFlag: "clearingFlag",
  clearingPrice: "clearingPrice",
  clearReportRiskVals: FieldNames.clearReportRiskVals,
  clearingStatus: "clearingStatus",
  contraBranchSeqNum: "contraBranchSeqNum",
  contraCapacity: "contraCapacity",
  contraClearingNum: "contraClearingNum",
  contraExecID: "contraExecID",
  contraGiveUpMPID: "contraGiveUpMPID",
  contraMPID: "contraMPID",
  contraOrderID: "contraOrderID",
  contraReferenceNum: "contraReferenceNum",
  contraShortSaleInd: "contraShortSaleInd",
  contraTradeReportID: "contraTradeReportID",
  controlNum: "controlNum",
  correspondentMPID: "correspondentMPID",
  CUSIP: "cusip",
  symbolOrCUSIP: "symbolOrCUSIP",
  entryI1I2: "entryI1I2",
  entryMPID: "entryMPID",
  entryMethodFlag: "entryMethodFlag",
  entrySymbol: "entrySymbol",
  entryTradeModifiers: "entryTradeModifiers",
  execID: "execID",
  executingBranchSeqNum: "executingBranchSeqNum",
  executingCapacity: "executingCapacity",
  executingClearingNum: "executingClearingNum",
  executingGiveUpMPID: "executingGiveUpMPID",
  executingMPID: "executingMPID",
  executionDate: "executionDate",
  executionTime: "executionTimeNs",
  fee: "fee",
  id: "id",
  intendedMarketFlag: "intendedMarketFlag",
  lastUpdated: "lastUpdated",
  lastUpdateDate: "lastUpdateDate",
  lastUpdateI1I2: "lastUpdateI1I2",
  lastUpdateTime: "lastUpdateTime",
  lateTradeReportFlag: "lateTradeReportFlag",
  matchControlNum: "matchControlNum",
  matchFlag: "matchFlag",
  memo: "memo",
  modifier2: "modifier2",
  modifier2Input: "entryModifier2",
  modifier2Time: "modifier2TimeNs",
  modifier3: "modifier3",
  modifier3Input: "entryModifier3",
  modifier4: "modifier4",
  modifier4Input: "entryModifier4",
  modifier4Time: "modifier4TimeNs",
  MPIDType: "MPIDType",
  notional: "notional",
  orderID: "orderID",
  originalControlDate: "originalControlDate",
  originalControlNum: "originalControlNum",
  originalMarketFlag: "originalMarketFlag",
  portUser: "workstationUserId", // this isn't being sent back yet?
  previousTradeStatus: "previousTradeStatus",
  price: "price",
  priceOverride: "priceOverride",
  priceTradeDigit: "priceTradeDigit",
  publishFlag: "publishFlag",
  quantity: "quantity",
  referenceNum: "referenceNum",
  referenceReportingVenue: "referenceReportingVenue",
  rejectSource: "rejectSource",
  rejectStatus: "rejectStatus",
  rejectText: "rejectText",
  relatedMarketFlag: "relatedMarketFlag",
  reportingParty: "reportingParty",
  reversalFlag: "reversalFlag",
  riskEligibleFlag: "riskEligibleFlag",
  securityClass: "securityClass",
  sellerDays: "sellerDays",
  sentToClearing: "sentToClearing",
  settlement: "settlement",
  source: "workstationAction",
  side: "side",
  special: FieldNames.special,
  specialInstructions: FieldNames.specialInstructions,
  // special: "specialTradeFlag",
  stepInOut: FieldNames.stepInOut,
  symbol: "symbol",
  tradeModifiers: "tradeModifiers",
  tradeReferenceNum: "tradeReferenceNum",
  tradeReportFlag: "tradeReportFlag",
  tradeReportID: "tradeReportID",
  tradeStatus: "tradeStatus",
  tradeThrough: "tradeThroughExempt",
  trf: "trf",
  wasModifiedFlag: "wasModifiedFlag",
  workstationId: "workstationId",
  workstationUserId: "workstationUserID",
  alleged: "alleged",
  reportDate: "actDate",
  reportDateRange: "actDate",
  executingBroker: "executingBroker",
  rejectCount: "rejectCount",
  clearingFirmMPID: "clearingFirmMPID",
  clearingFirmNum: "clearingFirmNum",
  isT2EntryAllowed: "t2EntryAllowed",
  isRiskConfigActive: "active",
  dataSources: "dataSources",
  tradeSource: "tradeSource",
  buyDefaultAction: "buyDefaultAction",
  sellDefaultAction: "sellDefaultAction",
  useNetTrading: "useNetTrading",
  isAlertActive: "alertActive",
  buyAlertLimit: "buyAlertLimit",
  sellAlertLimit: "sellAlertLimit",
  isHoldActive: "holdActive",
  buyHoldLimit: "buyHoldLimit",
  sellHoldLimit: "sellHoldLimit",
  isRejectActive: "rejectActive",
  buyRejectLimit: "buyRejectLimit",
  sellRejectLimit: "sellRejectLimit",
  netAlertLimit: "netAlertLimit",
  netHoldLimit: "netHoldLimit",
  netRejectLimit: "netRejectLimit",
  isPerTradeActive: "perTradeActive",
  holdDefaultAction: "holdDefaultAction",
  perTradeBuyLimit: "perTradeBuyLimit",
  perTradeSellLimit: "perTradeSellLimit",
  perTradeBuyAction: "perTradeBuyAction",
  perTradeSellAction: "perTradeSellAction",
  perTradeAction: "perTradeAction",
  isMaxTradeActive: "maxTradeActive",
  maxTradeLimit: "maxTradeLimit",
  trfLimits: "updateTrfLimits",
  totalBuy: "ba",
  totalSell: "sa",
  totalNet: "na",
  totalBuyProforma: "pba",
  totalSellProforma: "psa",
  totalNetProforma: "pna",
  ackType: "ackType",
  defaultAction: "defaultAction",

  execBreachFlag: "btExecFlag",
  contraBreachFlag: "btContraFlag",
  clearingNum: "clearingNum",
  firmType: "firmType",
  // HkStats
  activeHeldId: "activeHeldId",
  heldTrades: "heldTrades",
  heldCount: "heldCount",
  heldCountPerTrade: "heldCountPerTrade",

  activeKilledId: "activeKilledId",
  killedTrades: "killedTrades",
  killedCount: "killedCount",
  killedCountPerTrade: "killedCountPerTrade",
  issueID: "issueId",
  nasdaqSymbol: "nasdaqSymbol",
  siacSymbol: "siacSymbol",
  tradeEntrySymbol: "tradeEntrySymbol",
  name: "name",
  market: "market",
  description: "description",
  actEligible: "actEligible",
  clearingEligible: "clearingEligible",
  status: "status",
  executingBrokerMPID: "qsrbroker",
  qsrBrokerMPID: "executingBroker",
  mpid: "mpid",
  firstValidDay: "firstValidDay",
  firstValidDate: "firstValidDate",
  lastValidDay: "lastValidDay",
  lastValidDate: "lastValidDate",

  brokerMPID: "brokerMPID",
  clearingFirmNSCCNumber: "clearingFirmNSCCNumber",
  canEnterAsOfTrades: "canEnterAsOfTrades",
  defaultClearing: "isDefaultClearing",
  clearingRelationship: "clearingRelationship",
  trfeligible: "trfEligible",

  uploadStatus: "status",
  uploadMessage: "message",

  errorMessages: "errorMessages",
  errorCode: "errorCode",

  clearingRelationshipId: "clrRelId",

  userId: "userId",
  timestamp: "timestamp",
  action: "action",
};

export const ErrorCode = {
  BAD_REQUEST: "REQUEST",
};

const getStringValue = (attr: any) => {
  if (typeof attr === "object") {
    return `${attr.value}`;
  }
  return `${attr}`;
};

export const ApiToFieldMap = {
  [ApiResponseNames.account]: undefined,
  [ApiResponseNames.actDate]: FieldNames.actDate,
  [ApiResponseNames.actReadyFlag]: undefined,
  [ApiResponseNames.actTime]: FieldNames.actTime,
  [ApiResponseNames.adjustedSpecialTradeFlag]: undefined,
  [ApiResponseNames.adjustedStepOut]: undefined,
  [ApiResponseNames.agreement]: FieldNames.agreement,
  [ApiResponseNames.advertisementInstruction]: undefined,
  [ApiResponseNames.asOf]: FieldNames.asOf,
  [ApiResponseNames.aguParticipant]: FieldNames.aguParticipant,
  [ApiResponseNames.qsrParticipant]: FieldNames.qsrParticipant,
  [ApiResponseNames.breakState]: FieldNames.breakState,
  [ApiResponseNames.carryOverFlag]: undefined,
  [ApiResponseNames.clearingPrice]: FieldNames.clearingPrice,
  [ApiResponseNames.clearReportRiskVals]: FieldNames.clearReportRiskVals,
  [ApiResponseNames.CUSIP]: FieldNames.CUSIP,

  [ApiResponseNames.contraBranchSeqNum]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Contra
      ? FieldNames.branchSeqNum
      : FieldNames.counterBranchSeqNum,
  [ApiResponseNames.contraCapacity]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Contra
      ? FieldNames.capacity
      : FieldNames.counterCapacity,
  [ApiResponseNames.contraClearingNum]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Contra
      ? FieldNames.clearingNum
      : FieldNames.counterClearingNum,
  [ApiResponseNames.contraExecID]: undefined,
  [ApiResponseNames.contraGiveUpMPID]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Contra
      ? FieldNames.giveUpMPID
      : FieldNames.counterGiveUpMPID,
  [ApiResponseNames.contraMPID]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Contra
      ? FieldNames.mpid
      : FieldNames.counterMPID,
  [ApiResponseNames.contraOrderID]: undefined,
  [ApiResponseNames.contraReferenceNum]: undefined,
  [ApiResponseNames.contraShortSaleInd]: FieldNames.contraShortSaleInd,
  [ApiResponseNames.contraTradeReportID]: undefined,
  [ApiResponseNames.controlNum]: FieldNames.controlNum,
  [ApiResponseNames.correspondentMPID]: FieldNames.correspondentMPID,
  [ApiResponseNames.entryI1I2]: undefined,
  [ApiResponseNames.entryMPID]: undefined,
  [ApiResponseNames.entryMethodFlag]: undefined,
  [ApiResponseNames.entrySymbol]: undefined, // might be FieldNames.symbol
  [ApiResponseNames.entryTradeModifiers]: undefined,
  [ApiResponseNames.execID]: undefined,
  [ApiResponseNames.executingBranchSeqNum]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Exec
      ? FieldNames.branchSeqNum
      : FieldNames.counterBranchSeqNum,
  [ApiResponseNames.executingCapacity]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Exec
      ? FieldNames.capacity
      : FieldNames.counterCapacity,
  [ApiResponseNames.executingClearingNum]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Exec
      ? FieldNames.clearingNum
      : FieldNames.counterClearingNum,
  [ApiResponseNames.executingGiveUpMPID]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Exec
      ? FieldNames.giveUpMPID
      : FieldNames.counterGiveUpMPID,
  [ApiResponseNames.executingMPID]: (data: any) =>
    getStringValue(data[ApiResponseNames.reportingParty]) === ReportingParty.Exec
      ? FieldNames.mpid
      : FieldNames.counterMPID,
  [ApiResponseNames.executionDate]: FieldNames.executionDate,
  [ApiResponseNames.executionTime]: FieldNames.executionTime,
  [ApiResponseNames.executingBroker]: FieldNames.executingBroker,
  [ApiResponseNames.fee]: FieldNames.fee,
  [ApiResponseNames.id]: undefined,
  [ApiResponseNames.intendedMarketFlag]: FieldNames.intendedMarketFlag,
  [ApiResponseNames.lastUpdateDate]: undefined,
  [ApiResponseNames.lastUpdateI1I2]: undefined,
  [ApiResponseNames.lastUpdateTime]: undefined,
  [ApiResponseNames.lateTradeReportFlag]: undefined,
  [ApiResponseNames.matchControlNum]: undefined,
  [ApiResponseNames.matchFlag]: undefined,
  [ApiResponseNames.memo]: FieldNames.memo,
  [ApiResponseNames.modifier2]: FieldNames.modifier2,
  [ApiResponseNames.modifier2Input]: FieldNames.modifier2Input,
  [ApiResponseNames.modifier2Time]: FieldNames.modifier2Time,
  [ApiResponseNames.modifier3]: FieldNames.modifier3,
  [ApiResponseNames.modifier3Input]: FieldNames.modifier3Input,
  [ApiResponseNames.modifier4]: FieldNames.modifier4,
  [ApiResponseNames.modifier4Input]: FieldNames.modifier4Input,
  [ApiResponseNames.modifier4Time]: FieldNames.modifier4Time,
  [ApiResponseNames.MPIDType]: undefined,
  [ApiResponseNames.notional]: undefined,
  [ApiResponseNames.orderID]: undefined,
  [ApiResponseNames.originalControlDate]: undefined,
  [ApiResponseNames.originalControlNum]: FieldNames.originalControlNum,
  [ApiResponseNames.originalMarketFlag]: undefined,
  [ApiResponseNames.portUser]: FieldNames.portUser,
  [ApiResponseNames.previousTradeStatus]: undefined,
  [ApiResponseNames.price]: FieldNames.price,
  [ApiResponseNames.priceOverride]: FieldNames.priceOverride,
  [ApiResponseNames.priceTradeDigit]: undefined,
  [ApiResponseNames.publishFlag]: undefined,
  [ApiResponseNames.quantity]: FieldNames.quantity,
  [ApiResponseNames.referenceNum]: undefined,
  [ApiResponseNames.referenceReportingVenue]: undefined,
  [ApiResponseNames.rejectSource]: undefined,
  [ApiResponseNames.rejectStatus]: undefined,
  [ApiResponseNames.rejectText]: undefined,
  [ApiResponseNames.rejectCount]: FieldNames.rejectCount,
  [ApiResponseNames.relatedMarketFlag]: FieldNames.relatedMarketFlag,
  [ApiResponseNames.reportingParty]: FieldNames.reportingParty,
  [ApiResponseNames.reversalFlag]: undefined,
  [ApiResponseNames.securityClass]: undefined,
  [ApiResponseNames.sellerDays]: undefined,
  [ApiResponseNames.sentToClearing]: undefined,
  [ApiResponseNames.settlement]: FieldNames.settlement,
  [ApiResponseNames.side]: FieldNames.side,
  [ApiResponseNames.special]: FieldNames.special,
  [ApiResponseNames.specialInstructions]: FieldNames.specialInstructions,
  [ApiResponseNames.stepInOut]: FieldNames.stepInOut,
  [ApiResponseNames.symbol]: FieldNames.symbol,
  [ApiResponseNames.symbolOrCUSIP]: FieldNames.symbolOrCUSIP,
  [ApiResponseNames.tradeModifiers]: undefined,
  [ApiResponseNames.tradeReferenceNum]: FieldNames.tradeReferenceNum,
  [ApiResponseNames.tradeReportID]: undefined,
  [ApiResponseNames.tradeStatus]: FieldNames.tradeStatusVals,
  [ApiResponseNames.tradeThrough]: FieldNames.tradeThrough,
  [ApiResponseNames.trf]: FieldNames.trf,
  [ApiResponseNames.wasModifiedFlag]: undefined,
  [ApiResponseNames.workstationId]: FieldNames.workstationId,
  [ApiResponseNames.workstationUserId]: undefined,
  [ApiResponseNames.alleged]: undefined,
  [ApiResponseNames.reportDateRange]: FieldNames.actDate,
};

export const FieldToApiMap = {
  [FieldNames.mpid]: ApiResponseNames.executingMPID,
  [FieldNames.trf]: ApiResponseNames.trf,
  [FieldNames.reportingParty]: ApiResponseNames.reportingParty,
  [FieldNames.clearReportRiskVals]: ApiResponseNames.clearReportRiskVals,
  [FieldNames.agreement]: ApiResponseNames.agreement,
  [FieldNames.counterMPID]: ApiResponseNames.contraMPID,
  [FieldNames.correspondentMPID]: ApiResponseNames.correspondentMPID,
  [FieldNames.side]: ApiResponseNames.side,
  [FieldNames.symbol]: ApiResponseNames.symbol,
  [FieldNames.quantity]: ApiResponseNames.quantity,
  [FieldNames.price]: ApiResponseNames.price,
  [FieldNames.priceOverride]: ApiResponseNames.priceOverride,
  [FieldNames.actDate]: ApiResponseNames.actDate,
  [FieldNames.actTime]: ApiResponseNames.actTime,
  [FieldNames.settlement]: ApiResponseNames.settlement,
  [FieldNames.counterCapacity]: ApiResponseNames.contraCapacity,
  [FieldNames.counterGiveUpMPID]: ApiResponseNames.contraGiveUpMPID,
  [FieldNames.counterClearingNum]: ApiResponseNames.contraClearingNum,
  [FieldNames.capacity]: ApiResponseNames.executingCapacity,
  [FieldNames.giveUpMPID]: ApiResponseNames.executingGiveUpMPID,
  [FieldNames.branchSeqNum]: ApiResponseNames.executingBranchSeqNum,
  [FieldNames.clearingNum]: ApiResponseNames.executingClearingNum,
  [FieldNames.modifier2]: ApiResponseNames.modifier2,
  [FieldNames.modifier2Time]: ApiResponseNames.modifier2Time,
  [FieldNames.modifier3]: ApiResponseNames.modifier3,
  [FieldNames.modifier4]: ApiResponseNames.modifier4,
  [FieldNames.modifier4Time]: ApiResponseNames.modifier4Time,
  [FieldNames.tradeThrough]: ApiResponseNames.tradeThrough, // could be ApiResponseNames.tradeThroughExempt ?
  [FieldNames.relatedMarketFlag]: ApiResponseNames.relatedMarketFlag,
  [FieldNames.intendedMarketFlag]: ApiResponseNames.intendedMarketFlag,
  [FieldNames.stepInOut]: ApiResponseNames.stepInOut,
  [FieldNames.fee]: ApiResponseNames.fee,
  [FieldNames.clearingPrice]: ApiResponseNames.clearingPrice,
  [FieldNames.special]: ApiResponseNames.special,
  [FieldNames.specialInstructions]: ApiResponseNames.specialInstructions,
  [FieldNames.memo]: ApiResponseNames.memo,
  [FieldNames.tradeReferenceNum]: ApiResponseNames.tradeReferenceNum,
  [FieldNames.dateRange]: undefined,
  [FieldNames.reportDateRange]: undefined,
  [FieldNames.executingBroker]: ApiResponseNames.executingBroker,

  [FieldNames.controlNum]: ApiResponseNames.controlNum,
  [FieldNames.tradeStatusVals]: ApiResponseNames.tradeStatus,
  [FieldNames.portUser]: ApiResponseNames.portUser,
  [FieldNames.asOf]: ApiResponseNames.asOf,
  [FieldNames.alleged]: ApiResponseNames.alleged,
  [FieldNames.executionDate]: ApiResponseNames.executionDate,
  [FieldNames.executionTime]: ApiResponseNames.executionTime,
  [FieldNames.workstationId]: ApiResponseNames.workstationId,
  [FieldNames.breakState]: ApiResponseNames.breakState,
  [FieldNames.originalControlNum]: ApiResponseNames.originalControlNum,
  [FieldNames.originalControlDate]: ApiResponseNames.originalControlDate,
  [FieldNames.referenceReportingVenue]: ApiResponseNames.referenceReportingVenue,
};

export const ScanDateType = {
  TRADE_DATE: "T",
  REPORT_DATE: "R",
};

export const PriceTradeDigit = {
  PER_SHARE: "A",
  CONTRACT: "B",
};

export const ExportLimitTypes = {
  INTRADAY: "IDL",
  NEXTDAY: "NDL",
};
