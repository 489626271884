import { Button, Flex, NotificationHub, SectionBody, SectionHeader } from "@nef/core";
import { FormFields } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { Form2 } from "components/form";
import Left from "components/left";
import React, { useCallback, useState } from "react";
import { OpenClose } from "components/reportScan";
import { useLayoutContext, useViewModelContext } from "../context";
import { useFormContext, useFormDispatch } from "../form";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "../user";
import {
  convertRashOrderPrices,
  MATH_OPERATOR,
  ORDER_ENTRY_SELECT_INPUTS,
  RashOrder,
  rashPriceFields,
  RASH_ORDER,
} from "./constants";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import {
  formValidationCheck,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { StandardTables } from "wksConstants";
import { formatUrl } from "utils/js.utils";
import styled from "styled-components";
import { PEG_DIFFERENCE_SIGN } from "./constants";

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const DEFAULT_ORDER_ENTRY_VALUES = {
  [RASH_ORDER.discretionPegType]: "N",
  [RASH_ORDER.discretionPrice]: 0,
  [RASH_ORDER.discretionPegDifference]: 0,
  [RASH_ORDER.discretionPegDifferenceSign]: "+",
  [RASH_ORDER.pegType]: "N",
  [RASH_ORDER.pegDifference]: 0,
  [RASH_ORDER.pegDifferenceSign]: "+",
  [RASH_ORDER.firm]: "INET",
  [RASH_ORDER.minQuantity]: 0,
  [RASH_ORDER.maxFloor]: 0,
  [RASH_ORDER.randomReserve]: 0,
  [RASH_ORDER.customerType]: "N",
  [RASH_ORDER.subId]: "0",
  [RASH_ORDER.timeInForce]: 99998,
  [RASH_ORDER.intermarketSweep]: "N",
  [RASH_ORDER.crossType]: "N",
  [RASH_ORDER.reactiveTradeNow]: "N",
};

const setPegOffsets = (
  body: RashOrder,
  offset: RASH_ORDER.pegDifference | RASH_ORDER.discretionPegDifference,
  offsetSign: RASH_ORDER.pegDifferenceSign | RASH_ORDER.discretionPegDifferenceSign
) => {
  if (body[offset] < 0) {
    body[offset] = Math.abs(body[offset]);
    body[offsetSign] = PEG_DIFFERENCE_SIGN.MINUS;
  }
};

export const OrderEntryLeft = () => {
  const [userData] = useUserContext();
  const formDispatch = useFormDispatch();
  const [layoutData] = useLayoutContext();
  const [formData] = useFormContext();
  const [viewData] = useViewModelContext();
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [loading, setLoading] = useState(false);

  const handleClearAll = useCallback(() => {
    formDispatch({
      type: "REVERT_FORM",
      payload: { form: Forms.ORDER_ENTRY, entitlements: userData.entitlements },
    });
  }, [formDispatch, userData.entitlements]);

  const handleInitForm = useCallback(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: {
        entitlements: userData.entitlements,
        form: Forms.ORDER_ENTRY,
      },
    });
  }, [formDispatch, userData.entitlements]);

  const handleDisabled = useCallback(() => {
    NotificationHub.send("warning", "No record selected");
  }, []);

  const entrySuccessCb = useCallback(
    (data: RashOrder) => {
      if (data.isReject) {
        NotificationHub.send("danger", "Order Entry Rejected", { subtitle: data.rejectMsg });
      } else {
        NotificationHub.send("success", "Order Entry Success");
        convertRashOrderPrices(data, MATH_OPERATOR.DIVIDE);
        tableDispatch([
          { type: "DESELECT_ALL_ROWS", payload: { table: StandardTables.ORDER_ENTRY } },
          {
            type: "ADD_TABLE_DATA_FIRST",
            payload: { table: StandardTables.ORDER_ENTRY, data },
          },
        ]);
      }
      setLoading(false);
    },
    [tableDispatch]
  );

  const entryErrorCb = useCallback((json: any) => {
    NotificationHub.send("danger", "Order Entry Error");
    setLoading(false);
  }, []);

  const handleOrderEntry = useCallback(() => {
    const valid = formValidationCheck({
      formData,
      formDispatch,
      form: Forms.ORDER_ENTRY,
      userData,
    });
    if (!valid) {
      return;
    }

    setLoading(true);

    const body = {
      ...DEFAULT_ORDER_ENTRY_VALUES,
      ...formData[Forms.ORDER_ENTRY.key].fields,
    };

    setPegOffsets(body, RASH_ORDER.pegDifference, RASH_ORDER.pegDifferenceSign);
    setPegOffsets(body, RASH_ORDER.discretionPegDifference, RASH_ORDER.discretionPegDifferenceSign);

    ORDER_ENTRY_SELECT_INPUTS.forEach(fieldName => {
      if (body[fieldName]?.value) {
        body[fieldName] = body[fieldName].value;
      }
    });
    convertRashOrderPrices(body, MATH_OPERATOR.MULTIPLY);
    for (let i = 0; i < rashPriceFields.length; i++) {
      if (isNaN(body[rashPriceFields[i]])) {
        NotificationHub.send("danger", "One of the price fields is not a number.");
        setLoading(false);
        return;
      }
    }
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_RASH_WS, `order/${body[RASH_ORDER.exchange]}/entry`),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      },
      entrySuccessCb,
      entryErrorCb
    );
  }, [entryErrorCb, entrySuccessCb, formData, formDispatch, userData]);

  return (
    <Left isDisabled={undefined} onDisabledClick={undefined}>
      <SectionHeader>
        <StyledFlex flexDirection={"column"}>
          <OpenClose form={Forms.ORDER_ENTRY} handleClearAll={handleClearAll} buttons={undefined} />
        </StyledFlex>
      </SectionHeader>
      <SectionBody>
        <Form2
          form={Forms.ORDER_ENTRY}
          isLeftExpanded={layoutData.isLeftExpanded}
          onInit={handleInitForm}
          leftFieldSet={FormFields[Forms.ORDER_ENTRY.key].left}
          rightFieldSet={FormFields[Forms.ORDER_ENTRY.key].right}
          onDisabledClick={handleDisabled}
          globalErrors={formData[Forms.ORDER_ENTRY.key].globalErrorMessage}
          isDisabled={viewData[`${Forms.ORDER_ENTRY.key}Disabled`]}
        />
      </SectionBody>
      <Button
        onClick={handleOrderEntry}
        isLoading={loading}
        disabled={tableData[StandardTables.ORDER_ENTRY].isLoading}
      >
        Send
      </Button>
    </Left>
  );
};
