import { NotificationHub } from "@nef/core";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Status, UploadStatus } from "wksConstants";
import { network, UploadSuccessNotification } from ".";
import { useStandardTableDispatch } from "../standardTable";
import { StandardTables } from "../../wksConstants";
import { formatUrl } from "../../utils/js.utils";
import { userContext, useUserContext } from "../user";
import { useRefDataContext } from "../refData";
import { Views } from "../../viewConstants";

const uploadCacheDispatch = createContext();
uploadCacheDispatch.displayName = "UploadCacheDispatch";
export const useUploadCacheDispatch = () => {
  return useContext(uploadCacheDispatch);
};

const uploadCacheContext = createContext();
uploadCacheContext.displayName = "UploadCacheContext";
export const useUploadCacheContext = () => {
  return useContext(uploadCacheContext);
};

const defaultState = {
  status: Status.NO_STATUS,
  isPolling: false,
  makeRequest: true,
  requestTimeout: null,
  shouldAbort: false,
  fileData: [],
  shouldGetFiles: false,
  pollIds: [],
  queue: {},
  processing: {},
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_MAKE_REQUEST": {
      return { ...state, makeRequest: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...state, status: action.payload };
    }
    case "SET_SHOULD_ABORT": {
      return { ...state, shouldAbort: action.payload };
    }
    case "SET_REQUEST_TIMEOUT": {
      return { ...state, requestTimeout: action.payload };
    }
    case "SET_GET_FILES": {
      return { ...state, shouldGetFiles: action.payload };
    }
    case "SET_FILES": {
      return { ...state, fileData: action.payload };
    }
    case "SET_POLL_IDS": {
      return { ...state, pollIds: action.payload };
    }
    case "SET_QUEUE": {
      return { ...state, queue: action.payload };
    }
    case "ADD_TO_QUEUE": {
      const file = action.payload;
      const queue = { ...state.queue };
      queue[file.id] = file;
      return { ...state, queue };
    }
    case "REMOVE_FROM_QUEUE": {
      const file = action.payload;
      const queue = { ...state.queue };
      delete queue[file?.id];
      return { ...state, queue };
    }
    case "SET_PROCESSING": {
      return { ...state, processing: action.payload };
    }
    case "ADD_TO_PROCESSING": {
      const file = action.payload;
      const processing = { ...state.processing };
      processing[file.id] = file;
      return { ...state, processing };
    }
    case "REMOVE_FROM_PROCESSING": {
      const file = action.payload;
      const processing = { ...state.processing };
      if (processing[file?.id] !== undefined) {
        NotificationHub.send(
          "success",
          <UploadSuccessNotification id={file.id} fileName={file.fileName} />
        );
        delete processing[file?.id];
      }
      return { ...state, processing };
    }
    case "REMOVE_POLL_ID": {
      const ids = Array.from(state.pollIds);
      const index = ids.indexOf(action.payload);
      if (index !== -1) {
        ids.splice(index, 1);
      }
      return { ...state, pollIds: ids };
    }
    case "UPDATE_FILE_WITH_ID": {
      const { file, value, isProcessing } = action.payload;
      const newState = { ...state };
      const fileData = Array.from(state.fileData);
      const index = fileData.findIndex(row => row.id === value);
      if (index !== -1) {
        fileData[index] = Object.assign({}, fileData[index], file);
        newState.fileData = fileData;
        if (isProcessing) {
          newState.pollIds = Array.from(state.pollIds);
          if (newState.pollIds.indexOf(file.id) === -1) {
            newState.pollIds.push(file.id);
          }
        }
      }
      return newState;
    }
    case "ADD_NEW_FILE": {
      const fileData = Array.from(state.fileData);
      fileData.unshift(action.payload);
      return { ...state, fileData };
    }
    case "DELETE_FILE_WIIH_ID": {
      const fileData = Array.from(state.fileData);
      const index = fileData.findIndex(file => file.id === action.payload);
      if (index !== -1) {
        fileData.splice(index, 1);
      }
      return { ...state, fileData };
    }
    default:
      return { ...state };
  }
};

const getQueueAndProcessing = files => {
  return files.reduce(
    (acc, curr) => {
      switch (curr.status) {
        case UploadStatus.QUEUED:
          acc.queue[curr.id] = curr;
          acc.pollIds.push(curr.id);
          break;
        case UploadStatus.PROCESSING:
          acc.processing[curr.id] = curr;
          acc.pollIds.push(curr.id);
          break;
        default:
          break;
      }
      return acc;
    },
    { queue: {}, processing: {}, pollIds: [] }
  );
};

const UploadCacheProvider = ({ children, defaultData }) => {
  const standardTableDispatch = useStandardTableDispatch();
  const feedStatus = useRef(Status.NO_STATUS);
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign(defaultState, defaultData));
  const [abort, setAbort] = useState(null);
  const [user] = useUserContext();
  useEffect(() => {
    if (state.shouldGetFiles) {
      const uploadsCallback = files => {
        if (Array.isArray(files)) {
          const { queue, processing, pollIds } = getQueueAndProcessing(files);
          dispatchF([
            {
              type: "SET_FILES",
              payload: files,
            },
            {
              type: "SET_GET_FILES",
              payload: false,
            },
            {
              type: "SET_POLL_IDS",
              payload: pollIds,
            },
            {
              type: "SET_QUEUE",
              payload: queue,
            },
            {
              type: "SET_PROCESSING",
              payload: processing,
            },
            {
              type: "SET_REQUEST_STATUS",
              payload: Status.SUCCESS,
            },
          ]);
        }
      };
      const uploadsErrorCallback = reject => {
        let message =
          "Something went wrong when retrieving the upload file data for the current user.";
        if (reject?.message) {
          message = reject.message;
        }
        NotificationHub.send("danger", message);
        standardTableDispatch({
          type: "SET_NOT_LOADING",
          payload: { table: StandardTables.UPLOAD_FILES },
        });
        dispatchF({
          type: "SET_REQUEST_STATUS",
          payload: Status.SUCCESS,
        });
      };
      standardTableDispatch({
        type: "SET_LOADING",
        payload: { table: StandardTables.UPLOAD_FILES },
      });

      if (!user.allowed.views[Views.UPLOAD]) {
        return;
      }

      network().getUploads(uploadsCallback, uploadsErrorCallback);
    }
  }, [standardTableDispatch, state.shouldGetFiles, user.allowed.views]);

  useEffect(() => {
    if (state.shouldAbort) {
      if (abort) {
        abort.abort();
      }
      dispatchF([
        { type: "SET_SHOULD_ABORT", payload: false },
        { type: "SET_MAKE_REQUEST", payload: true },
      ]);
    }
  }, [state.activeKilledId, state.activeHeldId, abort, state.shouldAbort]);

  useEffect(() => {
    return () => clearTimeout(state.requestTimeout);
  }, [state.requestTimeout]);

  const getHKAbortCallback = useCallback(() => {}, []);

  const getProgressData = useCallback(() => {
    const getUploadCacheCallback = files => {
      const actions = [
        {
          type: "SET_REQUEST_STATUS",
          payload: Status.SUCCESS,
        },
      ];
      if (Array.isArray(files)) {
        const fileMap = {};
        const ids = [];
        files.forEach(file => {
          fileMap[file.id] = file;
          ids.push(file.id);
        });
        state.fileData.forEach(file => {
          if (ids.includes(file.id)) {
            const fileUpdate = fileMap[file.id];
            actions.push({
              type: "UPDATE_FILE_WITH_ID",
              payload: {
                value: fileUpdate.id,
                file: fileMap[file.id],
              },
            });

            switch (fileUpdate.status) {
              case UploadStatus.QUEUED: {
                actions.push({
                  type: "ADD_TO_QUEUE",
                  payload: fileUpdate,
                });
                break;
              }
              case UploadStatus.PROCESSING: {
                actions.push({
                  type: "REMOVE_FROM_QUEUE",
                  payload: fileUpdate,
                });
                actions.push({
                  type: "ADD_TO_PROCESSING",
                  payload: fileUpdate,
                });
                break;
              }
              default: {
                actions.push({
                  type: "REMOVE_FROM_QUEUE",
                  payload: fileUpdate,
                });
                actions.push({
                  type: "REMOVE_FROM_PROCESSING",
                  payload: fileUpdate,
                });
                actions.push({
                  type: "REMOVE_POLL_ID",
                  payload: fileUpdate.id,
                });
                break;
              }
            }
          }
        });
      }
      actions.push({
        type: "SET_REQUEST_TIMEOUT",
        payload: setTimeout(() => {
          dispatchF({ type: "SET_MAKE_REQUEST", payload: true });
        }, 1000),
      });
      dispatchF(actions);
    };

    const getUploadCacheError = () => {
      const actions = [];
      if (feedStatus.current !== Status.ERROR) {
        feedStatus.current = Status.ERROR;
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        NotificationHub.send("danger", "An error occurred while retrieving upload data.");
      }
      actions.push({
        type: "SET_REQUEST_TIMEOUT",
        payload: setTimeout(() => {
          dispatchF({ type: "SET_MAKE_REQUEST", payload: true });
        }, 1000),
      });
      dispatchF(actions);
    };

    const headers = getHeaders();
    const abortController = new AbortController();
    setAbort(abortController);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "uploader/poll"),
      {
        method: "post",
        headers,
        body: JSON.stringify(state.pollIds),
        signal: abortController.signal,
      },
      getUploadCacheCallback,
      getUploadCacheError,
      getHKAbortCallback
    );
  }, [state.pollIds, state.fileData, getHKAbortCallback]);

  useEffect(() => {
    if (state.pollIds.length > 0 && state.makeRequest && !state.shouldAbort) {
      getProgressData();
      dispatchF({ type: "SET_MAKE_REQUEST", payload: false });
    }
  }, [state.makeRequest, getProgressData, state.shouldAbort, state.pollIds]);

  return (
    <uploadCacheDispatch.Provider value={dispatchF}>
      <uploadCacheContext.Provider value={[state, dispatchF]}>
        {children}
      </uploadCacheContext.Provider>
    </uploadCacheDispatch.Provider>
  );
};

export default UploadCacheProvider;
